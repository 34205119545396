// @flow

import React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

export const questionConfig2 = [
  { type: 'single', questionKey: 'admin', choiceKeys: ['negative', 'neutral', 'positive'] },
  {
    type: 'multi',
    questionKey: 'adminEnthusiasms',
    subkeys: ['oral', 'topical', 'vaginal', 'injection', 'uterine', 'subcutaneous', 'surgical', 'penile'],
    choiceKeys: ['positive', 'neutral', 'negative'],
  },
];

const Survey2MBC = ({
  locale,
  navLinks,
  surveyStateMBC,
  answerSurveySingleMBC,
  answerSurveyMultiMBC,
  isAnyModalVisible,
  lexicon,
  sharedLexicon,
}: SurveyMBCProps): React.Element<*> => {
  const isNextButtonHidden = !questionConfig2.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));

  return (
    <>
      <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <SingleRadioSurveyQuestion
          texts={sharedLexicon.get('survey2MbcQuestions.0.texts')}
          choices={sharedLexicon.get('survey2MbcQuestions.0.choices')}
          ariaHidden={isAnyModalVisible}
          questionIndex={0}
          selectedChoiceIndex={['negative', 'neutral', 'positive'].indexOf(surveyStateMBC.admin)}
          clickHandler={(choiceIndex: number) => {
            answerSurveySingleMBC('admin', ['negative', 'neutral', 'positive'][choiceIndex]);
          }}
        />
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig2[0].questionKey]) && (
            <MultiPartSurveyQuestion
              texts={sharedLexicon.get('survey2MbcQuestions.1.texts')}
              choices={sharedLexicon.get('survey2MbcQuestions.1.choices')}
              categories={sharedLexicon.get('survey2MbcQuestions.1.categories')}
              ariaHidden={isAnyModalVisible}
              subkeys={['oral', 'topical', 'vaginal', 'injection', 'uterine', 'subcutaneous', 'surgical', 'penile']}
              questionKey="adminEnthusiasms"
              choiceKeys={['positive', 'neutral', 'negative']}
              answerSurveyMultiMBC={answerSurveyMultiMBC}
              currentChoice={surveyStateMBC.adminEnthusiasms}
            />
          )
        }
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMBC,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMBC,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(Survey2MBC);
