// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from './textComponents/TextComponents';
import type { PageProps, State } from '../../lib/types';
import NavContainer from './nav/NavContainer';
import ModalWithOverlay from './modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../actions/isAnyModalVisibleActions';

type IntroPageState = {
  isModalOn: boolean,
};

type IntroPageProps = PageProps & {
  children?: React.Node,
  nextPage: string,
  navInside?: boolean,
};

class IntroPage extends React.Component<IntroPageProps, IntroPageState> {
  constructor(props: IntroPageProps) {
    super(props);
    this.state = { isModalOn: true };
    this.modalCopyRef = React.createRef();
    this.headingRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.modalCopyRef.current) this.modalCopyRef.current.focus();
    this.props.setModalVisible();
  };

  modalClickHandler = () => {
    this.props.setModalInvisible();
    this.setState({ isModalOn: false }, () => {
      this.headingRef.current.focus();
    });
  };

  render() {
    const {
      locale,
      nextPage,
      children,
      navInside,
      isAnyModalVisible,
      lexicon,
      sharedLexicon,
    } = this.props;
    const { isModalOn } = this.state;
    const overflowHandleStyle = isModalOn ? { overflowY: 'hidden' } : {};

    const navigation = (
      <NavContainer
        nextPage={nextPage}
        locale={locale}
        isHidingNextButton={isModalOn}
        hideNotes
        sharedLexicon={sharedLexicon}
      />
    );

    return (
      <>
        <div
          className="aria"
          aria-hidden={isModalOn}
          style={overflowHandleStyle}
        >
          <div className="flex-group">
            <Headings
              str={lexicon.get('headings')}
              externalHeading1Ref={this.headingRef}
              ariaHidden={isAnyModalVisible}
            />
            <MarkdownBlock
              str={lexicon.get('paragraphs')}
              ariaHidden={isAnyModalVisible}
            />
            {navInside && navigation}
          </div>
        </div>

        <ModalWithOverlay
          id="disclaimer"
          clickHandler={this.modalClickHandler}
          isModalOn={isModalOn}
          closeBox="close"
        >
          <div className="modal-content">
            <h1
              id="disclaimer-modal-title"
              className="focus-start"
              tabIndex="-1"
              ref={this.modalCopyRef}
            >
              {lexicon.get('disclaimer')}
            </h1>
            <MarkdownBlock str={lexicon.get('legalText')} />
          </div>
        </ModalWithOverlay>

        {children}

        {!navInside && navigation}
      </>
    );
  }
}

const mapStateToProps = ({
  isAnyModalVisible,
}: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(IntroPage);
