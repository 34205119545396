// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import { connect } from 'react-redux';
import NavButton from './NavButton';
import Note from '../note/Note';
import './NavContainer.scss';

type NavContainerProps = {
  backPage?: string,
  nextPage?: string,
  locale: string,
  isAnyModalVisible: boolean,
  isHidingNextButton?: boolean,
  hideNotes?: boolean,
  sharedLexicon: Lexicon,
  backText: string,
  nextText: string,
};

const NavContainer = ({
  backPage,
  nextPage,
  locale,
  isHidingNextButton,
  hideNotes,
  isAnyModalVisible,
  sharedLexicon,
  backText = '',
  nextText = '',
}: NavContainerProps): React.Element<'div'> => (
  <div className="nav-buttons-container">
    {backPage
      ? <NavButton isHidden={isAnyModalVisible} str={backText || sharedLexicon.get('navigation.backText')} to={`/${locale}/${backPage}`} left />
      : <div />}
    {!hideNotes && <Note sharedLexicon={sharedLexicon} />}
    {nextPage && (
      <NavButton
        isHidden={isHidingNextButton || isAnyModalVisible}
        str={nextText || sharedLexicon.get('navigation.nextText')}
        to={`/${locale}/${nextPage}`}
      />
    )}
  </div>
);

NavContainer.defaultProps = {
  backPage: '',
  nextPage: '',
  isHidingNextButton: false,
  hideNotes: false,
};

const mapStateToProps = ({ isAnyModalVisible }: StateType): State => ({ isAnyModalVisible });

export default connect(mapStateToProps)(NavContainer);
