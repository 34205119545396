// @flow

import React from "react";
import Animal from "./Animal";
import "./StudyIdImage.scss";

type studyIdImageProps = {
  userId: string,
  size: string,
};

const StudyIdImage = ({
  userId,
  size,
}: studyIdImageProps): React.Element<*> => {
  //TODO
  const testUserId = userId || "red-owl";

  const [color, animal] = testUserId.split("-");
  const className = size ? `study-id-${size}` : "study-id-image";

  return (
    <div className={className}>
      <div className={color}>
        <h1>{testUserId}</h1>
        <Animal name={animal} />
      </div>
    </div>
  );
};

export default StudyIdImage;
