// @flow

import { questionConfig1 } from '../surveyMBC/Survey1MBC';
import { questionConfig2 } from '../surveyMBC/Survey2MBC';
import { questionConfig3 } from '../surveyMBC/Survey3MBC';
import { questionConfig4 } from '../surveyMBC/Survey4MBC';
import { pagesLexicon } from '../../../lib/lexiconConfig';
import { isMyPathPeripartum, isMyBC, isInControl } from '../../../lib/appMode';
import type { TableData } from '../../../lib/types';

type StateAndContent = {
  state: Object,
};

export const checkTables = ({ state }: Object, locale: string): Array<TableData> => {
  const { surveyStateMBC, barrierSurvey } = state;

  const lexicon = pagesLexicon.locale(locale);
  const buildRows = ({ possibleValues, rowLabels, data }: Object, isFiltering: boolean = false, filterValue: string = ''): Array<Object> => (
    Object.values(data)
      .map((value: string, i: number): Object => ({
        category: rowLabels && rowLabels[i],
        answer: possibleValues.indexOf(value)
      })).filter((value: string): boolean => {
        if (isFiltering && value === filterValue) return false;

        return true;
      })
  );

  // "penile" key is unused and we need to remove it before displaying
  // the responses to this question.  It is only there to satisfy the
  // mybc_recs engine.
  const { penile: _, ...adminEnthusiasms } = surveyStateMBC.adminEnthusiasms;

  const inControlCheckTable = isInControl
    ? {
      caption: lexicon.get('barrier_survey.yourBarriers'),
      headings: lexicon.get('shared.barrierSurveyValues'),
      rows: buildRows({
        possibleValues: [0, 1, 2, 3],
        rowLabels: lexicon.get('shared.barrierSurveyQuestions'),
        data: barrierSurvey
      })
    } : {};

  const myBcCheckTables = [
    {
      caption: lexicon.get('summary.howUsedPast'),
      headings: lexicon.get('shared.survey4MbcQuestions.0.choices').slice(0, -1),
      rows: buildRows({
        possibleValues: questionConfig4[0].choiceKeys,
        rowLabels: lexicon.get('shared.survey4MbcQuestions.0.categories'),
        data: surveyStateMBC.feelAboutMethods
      }, true, 'never')
    },
    {
      caption: lexicon.get('summary.howMethodUsed'),
      headings: lexicon.get('shared.survey2MbcQuestions.1.choices'),
      rows: buildRows({
        possibleValues: questionConfig2[1].choiceKeys,
        rowLabels: lexicon.get('shared.survey2MbcQuestions.1.categories'),
        data: adminEnthusiasms
      })
    },
    {
      caption: lexicon.get('summary.howOftedUsed'),
      headings: lexicon.get('shared.survey1MbcQuestions.3.choices'),
      rows: buildRows({
        possibleValues: questionConfig1[3].choiceKeys,
        rowLabels: lexicon.get('shared.survey1MbcQuestions.3.categories'),
        data: surveyStateMBC.frequencyEnthusiasms
      })
    },
    {
      caption: lexicon.get('summary.howSideEffects'),
      headings: lexicon.get('shared.survey3MbcQuestions.0.choices'),
      rows: buildRows({
        possibleValues: questionConfig3[0].choiceKeys,
        rowLabels: lexicon.get('shared.survey3MbcQuestions.0.categories'),
        data: surveyStateMBC.sideEffectBothers,
      })
    },
    {
      caption: lexicon.get('summary.howBenefits'),
      headings: lexicon.get('shared.survey3MbcQuestions.1.choices'),
      rows: buildRows({
        possibleValues: questionConfig3[1].choiceKeys,
        rowLabels: lexicon.get('shared.survey3MbcQuestions.1.categories'),
        data: surveyStateMBC.benefitEnthusiasms
      }),
    }
  ];

  if (isInControl) {
    return [
      ...myBcCheckTables,
      inControlCheckTable
    ];
  }

  return myBcCheckTables;
};

const whenPregnantAnswer = ({ state: { surveyStateMBC } }: StateAndContent, locale: string): string => {
  const timingIndex = questionConfig1[0].choiceKeys.indexOf(surveyStateMBC.timing);

  if (timingIndex === -1) return '';
  return pagesLexicon.locale(locale).get(`shared.survey1MbcQuestions.0.choices.${timingIndex}`);
};

const thoughtsOnPregnancy = ({ state }: StateAndContent, locale: string): Object => {
  const lexicon = pagesLexicon.locale(locale);

  if (isMyBC) {
    return {
      title: lexicon.get('summary.thoughtsSectionTitle'),
      rows: [
        null,
        null,
        { category: lexicon.get('summary.whenPregnant'), value: whenPregnantAnswer({ state }, locale) },
      ]
    };
  }
  const { surveyStateMP, currentMethods } = state;
  const currentMethodNames = Array.from(currentMethods).map((name: string): string => (lexicon.get(`shared.methodNames.${name}`)));

  let thoughtsRows = {
    title: lexicon.get('summary.thoughtsSectionTitle'),
    rows: [
      {
        category: lexicon.get('summary.thoughtsOnChildren'),
        value: typeof surveyStateMP[1] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.1.reportAnswers.${surveyStateMP[1]}`) : ''
      },
      {
        category: lexicon.get('summary.thoughtsOnPregnancy'),
        value: typeof surveyStateMP[2] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.2.choices.${surveyStateMP[2]}`) : ''
      },
      {
        category: lexicon.get('summary.whenPregnant'),
        value: whenPregnantAnswer({ state }, locale)
      },
      {
        category: lexicon.get('summary.howImportantAvoidingPregnancy'),
        value: typeof surveyStateMP[4] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.4.choices.${surveyStateMP[4]}`) : ''
      },
      {
        category: lexicon.get('summary.howHappyIfPregnant'),
        value: typeof surveyStateMP[5] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.5.choices.${surveyStateMP[5]}`) : ''
      },
      {
        category: lexicon.get('summary.howUpsetIfPregnant'),
        value: typeof surveyStateMP[6] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.6.choices.${surveyStateMP[6]}`) : ''
      },
      {
        category: lexicon.get('summary.currentMethods'),
        value: currentMethodNames.join(', ')
      },
      {
        category: lexicon.get('summary.currentMethodSatisfaction'),
        value: typeof surveyStateMP[7] === 'number' ? lexicon.get(`shared.mypathSurveyQuestions.7.choices.${surveyStateMP[7]}`) : ''
      },
    ],
  };

  if (isMyPathPeripartum) {
    thoughtsRows = {
      title: thoughtsRows.title,
      rows: [
        {
          category: lexicon.get('summary.thoughtsOnAnotherChild'),
          value: surveyStateMP[8] === undefined ? '' : lexicon.get(`shared.mypathSurveyQuestions.8.choices.${surveyStateMP[8]}`)
        },
        ...thoughtsRows.rows,
      ],
    };
  }

  return thoughtsRows;
};

const notesProps = ({ state: { notesReducer: { notes } } }: StateAndContent, locale: string): Object => ({
  rows: notes.split('\n\n'),
  title: pagesLexicon.locale(locale).get('summary.myQuestions'),
  emptyText: pagesLexicon.locale(locale).get('summary.noQuestions'),
});

const inControlNotesProps = ({ state: { notesReducer: { inControlNotes } } }: StateAndContent, locale: string): Object => ({
  rows: inControlNotes.split('\n\n'),
  title: pagesLexicon.locale(locale).get('summary.issuesForNavigators'),
  emptyText: pagesLexicon.locale(locale).get('summary.noIssues'),
});

const methodsToDiscuss = ({ state: { activeRecs } }: StateAndContent, locale: string): Object => ({
  methods: [...activeRecs],
  title: pagesLexicon.locale(locale).get('summary.methodsTitle'),
  noMethods: pagesLexicon.locale(locale).get('summary.methodsNone'),
  methodNames: pagesLexicon.locale(locale).get('shared.methodNames'),
});

const importantThings = ({ state: { surveyStateMBC } }: StateAndContent, locale: string): Object => ({
  title: pagesLexicon.locale(locale).get('summary.importantThings'),
  rows: [
    { category: pagesLexicon.locale(locale).get('summary.effectiveness'), answer: surveyStateMBC.effectiveness === 'very' },
    { category: pagesLexicon.locale(locale).get('summary.howUsed'), answer: surveyStateMBC.admin === 'positive' },
    { category: pagesLexicon.locale(locale).get('summary.howOften'), answer: surveyStateMBC.frequency === 'positive' },
  ]
});

const redFlags = ({ state: { surveyStateMBC: { concerns, migraine } } }: StateAndContent, locale: string): Array<string> => {
  const outputSet = new Set();
  const sharedObj = {
    title: pagesLexicon.locale(locale).get('summary.redFlags'),
    emptyText: pagesLexicon.locale(locale).get('summary.none')
  };

  if (!concerns) { return { ...sharedObj, rows: Array.from(outputSet) }; }

  // Blood clots, high blood pressure show up if selected.
  if (concerns.includes('bloodclots')) outputSet.add('bloodclots');
  if (concerns.includes('bloodPressure')) outputSet.add('bloodPressure');

  // Smoking and over 35 both show if both are selected.
  if (concerns.includes('over35') && concerns.includes('smoking')) {
    outputSet.add('over35');
    outputSet.add('smoking');
  }

  // Migraines and Bright Lights selected mean show "Migraines with Aura"
  if (concerns.includes('over35') && migraine.includes('brightlights')) outputSet.add('migraineWithAura');

  return {
    ...sharedObj,
    rows: Array.from(outputSet).map((name: string): string => (pagesLexicon.locale(locale).get(`shared.concerns.${name}`))),
  };
};

const sideEffectsBenefits = ({ state: { surveyStateMBC } }: StateAndContent, locale: string): Object => {
  const lexicon = pagesLexicon.locale(locale);
  const sideEffectsBenefitsKeys = Object.keys(surveyStateMBC.sideEffectBothers).concat(Object.keys(surveyStateMBC.benefitEnthusiasms));

  const doWant = sideEffectsBenefitsKeys
    .filter((key: string): Array<string> => (surveyStateMBC.sideEffectBothers[key] === 'positive' || surveyStateMBC.benefitEnthusiasms[key] === 'positive'))
    .map((key: string): Array<string> => lexicon.get(`shared.sideEffectNames.${key}`))
    .filter((v: string, i: number, a: [string]): boolean => a.indexOf(v) === i)
    .join(', ');

  const dontWant = sideEffectsBenefitsKeys
    .filter((key: string): Array<string> => (surveyStateMBC.sideEffectBothers[key] === 'negative' || surveyStateMBC.benefitEnthusiasms[key] === 'negative'))
    .map((key: string): Array<string> => lexicon.get(`shared.sideEffectNames.${key}`))
    .filter((v: string, i: number, a: [string]): boolean => a.indexOf(v) === i)
    .join(', ');

  return {
    title: lexicon.get('summary.sideEffectsBenefits'),
    rows: [
      { category: lexicon.get('summary.doWant'), value: doWant },
      { category: lexicon.get('summary.dontWant'), value: dontWant },
    ]
  };
};

const chosenTopics = (props: StateAndContent, locale: string): Object => {
  if (isMyBC) return null;

  const { topics } = props.state.topicReducer;

  return ({
    rows: topics,
    title: pagesLexicon.locale(locale).get('summary.topicsTitle'),
    emptyText: pagesLexicon.locale(locale).get('summary.topicsEmptyText'),
  });
};

export default ({ state }: StateAndContent, locale: string): Object => ({
  topics: chosenTopics({ state }, locale),
  methodsToDiscuss: methodsToDiscuss({ state }, locale),
  notes: notesProps({ state }, locale),
  inControlNotes: inControlNotesProps({ state }, locale),
  thoughtsOnPregnancy: thoughtsOnPregnancy({ state }, locale),
  importantThings: importantThings({ state }, locale),
  redFlags: redFlags({ state }, locale),
  sideEffectsBenefits: sideEffectsBenefits({ state }, locale),
  checkTables: checkTables({ state }, locale),
});
