// @flow

import React from 'react';
import { connect } from 'react-redux';
import { MobileFakeRibbon, RegularFakeRibbon } from '../../shared/ribbon/Ribbon';
import NavButton from '../../shared/nav/NavButton';
import type { State } from '../../../lib/types';

type ConditionalNavProps = PageProps & {
  activeRecs: Array<string>,
  navLinks: Array<string>,
  isHidden: boolean,
  noMethodButtonRef: HTMLButtonElement => void,
  noMethodModalClickHandler: () => void,
};

const ConditionalNav = ({ 
  locale,
  noMethodButtonRef,
  isHidden,
  activeRecs,
  navLinks,
  noMethodModalClickHandler,
  sharedLexicon,
  lexicon
}: ConditionalNavProps): React.Element<'div'> => (
  <div className="nav-buttons-container">
    <MobileFakeRibbon
      to={`/${locale}/${navLinks[0]}`}
      str={sharedLexicon.get('navigation.compareMethodsText')}
      isHidden={isHidden}
    />
    <RegularFakeRibbon
      to={`/${locale}/${navLinks[0]}`}
      str={sharedLexicon.get('navigation.compareMethodsText')}
      isHidden={isHidden}
    />
    {
      activeRecs.length > 0 ? (
        <NavButton
          str={sharedLexicon.get('navigation.nextText')}
          to={`/${locale}/${navLinks[1]}`}
          isHidden={isHidden}
        />
      ) : (
        <button
          className={`nav-button conditional ${isHidden ? 'no-display' : ''}`}
          aria-hidden={isHidden}
          onClick={noMethodModalClickHandler}
          type="button"
          ref={noMethodButtonRef}
          tabIndex={isHidden ? null : '0'}
        >
          {lexicon.get('conditionalNavText')}
        </button>
      )
    }
  </div>
);

const mapStateToProps2 = ({ activeRecs }: State): { activeRecs: Array<string> } => ({ activeRecs });
export default connect(mapStateToProps2)(ConditionalNav);
