// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import { connect } from 'react-redux';
import IconContainerWithMeasure from './IconContainerWithMeasure';
import InfoBox from '../../shared/infoBox/InfoBox';
import { MarkdownBlock } from '../../shared/textComponents/TextComponents';
import activeRecsActions from '../../../actions/activeRecsActions';
import { RecsObjTransformFunc } from '../../../lib/utils';
import type { State, RecEngineOutput, Action } from '../../../lib/types';

type RecSectionProps = {
  sectionText: string,
  sectionName: string,
  floatingBoxClickHandler: (string, string) => void,
  activeIconName: string,
  activeIconSection: string,
  toggleActiveRec: Action,
  recs: Object,
  iconExplanations: Object,
  lexicon: Lexicon,
  sharedLexicon: Lexicon,
  activeRecs: [string],
  ariaHidden: boolean,
  isFirst: boolean,
  locale: string,
  arrowConfig: {},
};

class RecSection extends React.Component<RecSectionProps> {
  componentDidMount() {
    if (this.props.isFirst) {
      window.scrollTo(0, 0);
      this._recSectionDiv.focus();
    }
  }

  recSectionDivRef = (c: ?HTMLDivElement) => { this._recSectionDiv = c; }

  render() {
    const {
      sectionText, sectionName, floatingBoxClickHandler, activeIconName, activeIconSection, toggleActiveRec,
      recs, iconExplanations, activeRecs, ariaHidden, locale, arrowConfig, lexicon, sharedLexicon,
    } = this.props;

    return (
      <div
        className={`rec-section-container focus-start ${sectionName}`}
        tabIndex={ariaHidden ? null : '-1'}
        ref={this.recsDivRef}
        aria-hidden={ariaHidden}
        role="group"
        aria-labelledby={`rec-intro-${sectionName}`}
      >
        <MarkdownBlock str={sectionText} contentRef={this.recSectionDivRef} id={`rec-intro-${sectionName}`} />
        <div className="rec-section-icons-container">
          {
            recs[sectionName].map((iconNameEnglish: string, index: number): React.Element<'div'> => {
              const iconName = sharedLexicon.get(`methodNames.${iconNameEnglish}`);
              const isIconActive = activeRecs.includes(iconNameEnglish);
              const actionHandler = (e: React.SyntheticEvent<HTMLSpanElement>) => { e.stopPropagation(); floatingBoxClickHandler(iconNameEnglish, sectionName); };
              const isFloatingBoxActive = iconNameEnglish === activeIconName && sectionName === activeIconSection;

              return (
                <IconContainerWithMeasure
                  key={iconName}
                  iconNameEnglish={iconNameEnglish}
                  iconName={iconName}
                  isActive={isIconActive}
                  positionStr={arrowConfig[sectionName]}
                  isFloatingBoxActive={isFloatingBoxActive}
                  toggleActiveRec={toggleActiveRec}
                  actionHandler={actionHandler}
                  index={index}
                  iconExplanation={iconExplanations[iconNameEnglish]}
                  ariaHidden={ariaHidden}
                  locale={locale}
                  sectionText={sectionText}
                  renderInfoBox={(style: {}): React.Element<InfoBox> => (
                    <InfoBox
                      isActive={isFloatingBoxActive}
                      iconName={iconName}
                      iconNameEnglish={iconNameEnglish}
                      iconExplanation={iconExplanations[iconNameEnglish]}
                      clickHandler={floatingBoxClickHandler}
                      style={style}
                      positionStr={arrowConfig[sectionName]}
                      closeButton={sharedLexicon.get('closeBox')}
                      isSpan
                    />
                  )}
                />
              );
            })
          }
          {
            recs[sectionName].length === 0 ? <p id="none-text">{lexicon.get('noneText')}</p> : null
          }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps1 = activeRecsActions;
const mapStateToProps1 = ({ surveyStateMBC, activeRecs }: State): RecEngineOutput => ({
  recs: RecsObjTransformFunc(surveyStateMBC),
  activeRecs,
});

export default connect(mapStateToProps1, mapDispatchToProps1)(RecSection);
