// @flow

import React from 'react';
import { connect } from 'react-redux';
import {
  Headings,
  MarkdownBlock,
} from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import FertilityByAge from './FertilityByAge';
import { isMyPathPeripartum, isMyPathVA } from '../../../lib/appMode';
import type { PageProps, State } from '../../../lib/types';

import MenstrualCycleImageEn from './menstrual-cycle@2x.png';
import MenstrualCycleImageEs from './menstrual-cycle-es@2x.png';
import './FertilityMenu.scss';

type FertilityMenuProps = PageProps & {
  contents: Object,
  visitedItems: Array<string>,
};

type FertilityMenuState = {
  visibleModal: string,
  isAnyModalVisible: boolean,
};

class FertilityMenu extends React.Component<
  FertilityMenuProps,
  FertilityMenuState,
> {
  constructor(props: FertilityMenuProps) {
    super(props);
    this.state = { visibleModal: null };
  }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) {
      onModalShow(item);
    }
  };

  hideModal = () => {
    const { onModalHide } = this.props;
    this.setState({ visibleModal: null });
    if (onModalHide) {
      onModalHide();
    }
  };

  render() {
    const { locale, visitedItems, isAnyModalVisible, lexicon, sharedLexicon } =
      this.props;
    const { visibleModal } = this.state;

    const itemProps = (
      item: string
    ): { item: string, question: string, modalCopy: string } => ({
      question: lexicon.get(`${item}_question`),
      modalCopy: lexicon.get(`${item}_modal_copy`),
      topics: lexicon.get(`${item}_topics`),
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: (): void => this.showModal(item),
      onModalHide: this.hideModal,
      visited: visitedItems.includes(item),
      buttonText: locale === 'es' ? 'ver guía' : 'view answer',
      sharedLexicon,
      locale,
    });

    return (
      <>
        <Headings
          isAutoFocusing
          str={lexicon.get('headings')}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock
          str={lexicon.get('paragraphs')}
          ariaHidden={isAnyModalVisible}
        />
        <div className={`menu-container ${isMyPathPeripartum ? 'single' : ''}`}>
          <MenuItem
            {...itemProps('menstrual')}
            className="first"
            isMyPath
            modalLabel="Answer"
          >
            {isMyPathVA && <p>{lexicon.get('menstrual_extra_text')}</p>}
            <figure>
              <figcaption
                id="menstrual-cycle-caption"
                className="screen-reader-only"
              >
                {lexicon.get('menstrual_image_alt')}
              </figcaption>
              <img
                id="menstrual-cycle-image"
                src={
                  locale === 'es'
                    ? MenstrualCycleImageEs
                    : MenstrualCycleImageEn
                }
                alt="menstrual cycle diagram"
                aria-describedby="menstrual-cycle-caption"
              />
            </figure>
          </MenuItem>

          {isMyPathPeripartum ? (
            <MenuItem
              {...itemProps('skip')}
              className="third"
              isMyPath
              modalLabel="Answer"
            />
          ) : (
            <>
              <MenuItem
                {...itemProps('skip')}
                className="second"
                isMyPath
                modalLabel="Answer"
              />
              <MenuItem
                {...itemProps('period')}
                className="third"
                isMyPath
                modalLabel="Answer"
              />
            </>
          )}
          <MenuItem
            {...itemProps('age')}
            className="fourth"
            isMyPath
            modalLabel="Answer"
          >
            <FertilityByAge lexicon={lexicon.subset('chart')} locale={locale} />
          </MenuItem>
          {!isMyPathPeripartum && (
            <>
              <MenuItem
                {...itemProps('fertility_service')}
                className="fifth"
                isMyPath
                modalLabel="Answer"
              />
              <MenuItem
                {...itemProps('health')}
                className="sixth"
                isMyPath
                modalLabel="Answer"
              />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: StateType): State => ({
  visitedItems: state.fertilityMenuReducer.visited,
  isAnyModalVisible: state.isAnyModalVisible,
});

const mapDispatchToProps = (dispatch: Dispatch): Object => ({
  onModalShow: (item: string): null => {
    dispatch({ type: 'SET_FERTILITY_ITEM_VISITED', item });
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: true });
  },
  onModalHide: (): null => {
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: false });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FertilityMenu);
