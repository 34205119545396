// @flow

import type { Action } from '../lib/types';

type SingleSurveyActionMBC = {
  +type: string,
  +questionKey: string,
  +value: string,
};

type MultiSurveyActionMBC = SingleSurveyActionMBC & {
  +category: string,
};

export default {
  answerSurveySingleMP: (index: number, value: number): Action => ({
    type: 'ANSWER_SURVEY_SINGLE_MP',
    index,
    value,
  }),
  answerSurveySingleMBC: (questionKey: string, value: string): SingleSurveyActionMBC => ({
    type: 'ANSWER_SURVEY_SINGLE_MBC',
    questionKey,
    value,
  }),
  answerSurveyPluralMBC: (questionKey: string, value: string): SingleSurveyActionMBC => ({
    type: 'ANSWER_SURVEY_PLURAL_MBC',
    questionKey,
    value,
  }),
  answerSurveyMultiMBC: (questionKey: string, category: string, value: string): MultiSurveyActionMBC => ({
    type: 'ANSWER_SURVEY_MULTI_MBC',
    questionKey,
    category,
    value,
  }),
  answerBarrierSurvey: (index: number, value: number): Action => ({
    type: 'ANSWER_BARRIER_SURVEY',
    index,
    value
  }),
  prefillSurvey4: (): { type: string } => ({ type: 'PREFILL_SURVEY4' }),
  secretPrefillSurvey: (): { type: string } => ({ type: 'SECRET_PREFILL_SURVEY' }),
  surveyLog: (question: string, answer: string, path: string): { type: string, question: string, answer: string, path: string} => ({
    type: 'SURVEY_CLICK',
    question,
    answer,
    path,
  }),
};
