// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Element } from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import MainNavArrowImg from '../nav/main_nav_check_white.svg';
import { MarkdownBlock } from '../textComponents/TextComponents';
import ModalWithOverlay from '../modal/ModalWithOverlay';
import Topic from '../topic/Topic';
import markdown from '../../../lib/utils';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import './MenuItem.scss';

type Props = {
  question: string,
  modalCopy: string,
  topics: string[] | undefined,
  sharedLexicon: Lexicon,
  visited: boolean,
  className: string,
  modalVisible: boolean,
  ariaHidden: boolean,
  buttonText: string,
  onModalShow: ?() => void,
  onModalHide: ?() => void,
  children: ?Element<any>,
  isMyPath?: boolean,
  setModalInvisible: () => {},
  setModalVisible: () => {},
  modalButtonLog: () => {},
  modalLabel?: string,
  locale: 'en' | 'es',
};

class MenuItem extends React.Component<Props> {
  static defaultProps = { isMyPath: false }

  _modalCopyDiv: ?HTMLDivElement;
  _menuItemButton: ?HTMLButtonElement;

  componentDidUpdate(prevProps: Props) {
    const { modalVisible } = this.props;
    if (!prevProps.modalVisible && modalVisible) {
      this._modalCopyDiv.focus();
      this._modalCopyDiv.scrollTop = 0;
    }
    if (prevProps.modalVisible && !modalVisible) {
      this._menuItemButton.focus();
    }
  }

  modalCopyDivRef = (c: ?HTMLDivElement) => { this._modalCopyDiv = c; }
  menuItemButtonRef = (c: ?HTMLButtonElement) => { this._menuItemButton = c; }

  modalShowHandler = () => {
    const { question, onModalShow, setModalVisible, modalButtonLog } = this.props;
    onModalShow();
    setModalVisible();
    modalButtonLog(question, window.location.pathname);
  }

  modalHideHandler = () => {
    const { onModalHide, setModalInvisible } = this.props;
    onModalHide();
    setModalInvisible();
  }

  render() {
    const {
      question,
      visited,
      topics,
      children,
      modalCopy,
      sharedLexicon,
      className,
      modalVisible,
      ariaHidden,
      buttonText,
      isMyPath,
      modalLabel,
      locale,
    } = this.props;

    return (
      <>
        <button
          className={`menu-item ${className} ${visited ? 'visited' : ''}`}
          aria-hidden={ariaHidden}
          ref={this.menuItemButtonRef}
          type="button"
          onClick={this.modalShowHandler}
          disabled={ariaHidden}
          tabIndex="0"
        >
          <div dangerouslySetInnerHTML={{ __html: markdown(question) }} />
          <div className="view-answer">
            {
              (isMyPath || !visited) ? buttonText : <img className="menu-item-check" src={MainNavArrowImg} alt="visited" />
            }
          </div>
        </button>
        <ModalWithOverlay
          isModalOn={modalVisible}
          clickHandler={this.modalHideHandler}
          extraClass="menu-item-modal"
          closeBox={sharedLexicon.get('closeBox')}
          modalLabel={modalLabel}
        >
          <header className="rainbow mobile">
            <div className="yellow-box box" />
            <div className="red-box box" />
            <div className="lime-box box" />
            <div className="green-box box" />
            <div className="blue-box box" />
            <div className="indigo-box box" />
          </header>
          <div className={`modal-content ${className}`}>
            <div
              ref={this.modalCopyDivRef}
              className="menu-item-modal-copy focus-start"
              tabIndex="-1"
            >
              <MarkdownBlock str={modalCopy} />
            </div>
            {children}
            {
              (topics && modalVisible) && topics.map((topic: string, i: number): React.Element<*> => (
                // topic is hidden when no modal is visible or if the opened modal is not the current one that contains topic
                <Topic
                  key={topic}
                  topic={topic}
                  locale={locale}
                  ariaHidden={!ariaHidden || !modalVisible}
                  isFirst={i === 0}
                />
              ))
            }
          </div>
          <div className="nav-buttons-container fake mobile">
            <button onClick={this.modalHideHandler} type="button" className="nav-button left">« {sharedLexicon.get('navigation.backText')}</button>
          </div>
        </ModalWithOverlay>
      </>
    );
  }
}

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(MenuItem);
