// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps, State } from '../../../lib/types';
import BurstImage from './side-effects-burst.png';
import './SideEffectPreface.scss';

const SideEffectPreface = ({ lexicon, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <img className="burst-image" src={BurstImage} alt="" aria-hidden />
    <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
    <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
  </>
);

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(SideEffectPreface);
