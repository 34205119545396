// @flow

import type { Action } from '../lib/types';

export default {
  setUserId: (value: string): Action => ({
    type: 'SET_USER_ID',
    value
  })
};
