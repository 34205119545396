// @flow

import React from 'react';
import { connect } from 'react-redux';
import SilhouetteInterface from '../../shared/silhouetteInterface/SilhouetteInterface';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps, State } from '../../../lib/types';
import './HowToMen.scss';

const howToMenConfig = {
  vasectomy: ['down', 'bottom', 'right'],
  maleCondom: ['up', 'bottom', 'left'],
};

const methods = [
  'maleCondom',
  'vasectomy',
];

const HowToMen = ({ isAnyModalVisible, lexicon, sharedLexicon }: PageProps): React.Element<*> => {
  const mappedIconNames = methods.map((str: string): string => sharedLexicon.get(`methodNames.${str}`));

  return (
    <>
      <div className="flexbox-group">
        <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
      </div>
      <SilhouetteInterface
        config={howToMenConfig}
        iconNamesEnglish={methods}
        iconNames={mappedIconNames}
        iconExplanations={lexicon.get('explanations')}
        sharedLexicon={sharedLexicon}
        isAnyModalVisible={isAnyModalVisible}
      />
    </>
  );
};

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(HowToMen);
