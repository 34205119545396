// @flow

import React from 'react';
import { connect } from 'react-redux';
import SurveyActions from '../../../actions/SurveyActions';
import NavContainer from '../../shared/nav/NavContainer';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import { numOfQuestionToUnlockMainNavs } from '../../../reducers/surveyReducerMP';
import { isQuestionFullyAnsweredFuncMP } from '../../../lib/utils';
import type { SurveyMPProps, State, MapStateToProps, pageProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';
import './CurrentMethodsSurvey.scss';

type BirthControlArraySurveyProps = pageProps & SurveyMPProps;

const BirthControlArraySurvey = ({
  locale,
  navLinks,
  surveyStateMP,
  answerSurveySingleMP,
  surveyLog,
  isAnyModalVisible,
  lexicon,
  sharedLexicon,
}: BirthControlArraySurveyProps): React.Element<*> => {
  const isNextButtonHidden = !isQuestionFullyAnsweredFuncMP(surveyStateMP[7]);

  return (
    <>
      <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
      <div className="survey-questions-container">
        <SingleRadioSurveyQuestion
          questionIndex={7}
          texts={sharedLexicon.get('mypathSurveyQuestions.7.texts')}
          choices={sharedLexicon.get('mypathSurveyQuestions.7.choices')}
          ariaHidden={isAnyModalVisible}
          isVertical
          selectedChoiceIndex={surveyStateMP[numOfQuestionToUnlockMainNavs]}
          clickHandler={(choiceIndex: number) => {
            answerSurveySingleMP(7, choiceIndex);
            surveyLog(sharedLexicon.get('mypathSurveyQuestions.7.texts'), window.location.pathname);
          }}
        />
      </div>
      {!isNextButtonHidden && (
        <div className="closing-text" aria-hidden={isAnyModalVisible}>
          <MarkdownBlock str={lexicon.get('closingTexts')} />
        </div>
      )}
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        backPage={navLinks[0]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMP,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMP,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(BirthControlArraySurvey);
