// @flow

import React from 'react';
import { connect } from 'react-redux';
import {
  MarkdownBlock,
  Headings,
} from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import { isQuestionFullyAnsweredFuncMP } from '../../../lib/utils';
import NavButton from '../../shared/nav/NavButton';
import Note from '../../shared/note/Note';
import SurveyActions from '../../../actions/SurveyActions';
import type { SurveyMPProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

type SurveyMPState = {
  pageNum: number,
};

type NextNavigationProps = {
  type: 'real' | 'fake',
  direction: 'back' | 'next',
  text: string,
  fakeClickHandler: () => void,
  realLinkTo: string,
  isHidden: boolean,
  isNextButtonHidden: boolean,
};

const questionRanges = [
  [0, 1, 2],
  [3, 4],
  [5, 6],
];
const choppedQuetionRanges = [[0, 1, 2], [4], [5, 6]];
const lastPageNum = questionRanges.length - 1;

const SmartNavigation = ({
  type,
  direction,
  text,
  fakeClickHandler,
  realLinkTo,
  isHidden,
  isNextButtonHidden,
}: NextNavigationProps) => {
  if (type === 'fake') {
    let fakeText = '';
    let extraClass = '';

    if (direction === 'back') {
      fakeText = `« ${text}`;
      extraClass = 'left';
    } else {
      fakeText = `${text} »`;

      if (isNextButtonHidden) extraClass = 'no-display ';
    }

    return (
      <button
        disabled={isHidden}
        aria-hidden={isHidden}
        type="button"
        onClick={fakeClickHandler}
        className={`nav-button ${extraClass}`}
      >
        {fakeText}
      </button>
    );
  }

  return (
    <NavButton
      str={text}
      isHidden={isHidden}
      to={realLinkTo}
      left={direction === 'back'}
    />
  );
};

class SurveyMP extends React.Component<SurveyMPProps, SurveyMPState> {
  _heading1: ?HTMLHeadingElement;

  constructor(props: SurveyMPProps) {
    super(props);
    this.state = { pageNum: 0 };
  }

  componentDidMount() {
    this._heading1.focus({ preventScroll: false });
  }

  externalHeading1Ref = (c: ?HTMLHeadingElement) => {
    this._heading1 = c;
  };

  fakeBackClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum - 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  };

  fakeNextClickHandler = () => {
    const { pageNum } = this.state;
    const { _heading1 } = this;
    this.setState({ pageNum: pageNum + 1 }, () => {
      _heading1.focus({ preventScroll: false });
    });
  };

  render() {
    const { pageNum } = this.state;
    const {
      locale,
      navLinks,
      surveyStateMP,
      answerSurveySingleMP,
      surveyLog,
      isAnyModalVisible,
      lexicon,
      sharedLexicon,
    } = this.props;

    const filteredQuestionRanges =
      surveyStateMP[2] === 3 ? choppedQuetionRanges : questionRanges;
    const isNextButtonHidden = !filteredQuestionRanges[pageNum].every(
      (index: number): boolean => typeof surveyStateMP[index] === 'number'
    );

    const backNavigationType = pageNum === 0 ? 'real' : 'fake';
    const nextNavigationType =
      pageNum === lastPageNum || surveyStateMP[2] === 0 ? 'real' : 'fake';

    const mypathSurveyQuestionsLexicon = sharedLexicon.subset(
      'mypathSurveyQuestions'
    );

    const q1texts =
      surveyStateMP[0] === 0
        ? mypathSurveyQuestionsLexicon.get('1.texts2')
        : mypathSurveyQuestionsLexicon.get('1.texts1');

    const answerHandlerMP = (qNum: number, choiceIdx: number) => {
      answerSurveySingleMP(qNum, choiceIdx);
      surveyLog(
        mypathSurveyQuestionsLexicon.get(`${qNum}.texts`),
        mypathSurveyQuestionsLexicon.get(`${qNum}.choices.${choiceIdx}`),
        window.location.pathname
      );
    };

    return (
      <>
        <Headings
          str={lexicon.get('headings')}
          externalHeading1Ref={this.externalHeading1Ref}
          ariaHidden={isAnyModalVisible}
        />
        {pageNum === 0 && (
          <>
            <MarkdownBlock
              str={lexicon.get('paragraphs')}
              ariaHidden={isAnyModalVisible}
            />
            <h2 className="screen-reader-only" aria-hidden={isAnyModalVisible}>
              {lexicon.get('hiddenText')}
            </h2>
            <div className="page-1 survey-questions-container">
              <SingleRadioSurveyQuestion
                questionIndex={0}
                texts={mypathSurveyQuestionsLexicon.get('0.texts')}
                choices={mypathSurveyQuestionsLexicon.get('0.choices')}
                ariaHidden={isAnyModalVisible}
                selectedChoiceIndex={surveyStateMP[0]}
                clickHandler={(choiceIndex: number) => {
                  answerHandlerMP(0, choiceIndex);
                }}
              />
              {isQuestionFullyAnsweredFuncMP(surveyStateMP[0]) && (
                <SingleRadioSurveyQuestion
                  questionIndex={1}
                  texts={q1texts}
                  choices={mypathSurveyQuestionsLexicon.get('1.choices')}
                  ariaHidden={isAnyModalVisible}
                  selectedChoiceIndex={surveyStateMP[1]}
                  clickHandler={(choiceIndex: number) => {
                    answerSurveySingleMP(1, choiceIndex);
                    surveyLog(
                      q1texts,
                      mypathSurveyQuestionsLexicon.get(
                        `1.choices.${choiceIndex}`
                      ),
                      window.location.pathname
                    );
                  }}
                />
              )}
              {isQuestionFullyAnsweredFuncMP(surveyStateMP[1]) && (
                <SingleRadioSurveyQuestion
                  questionIndex={2}
                  texts={mypathSurveyQuestionsLexicon.get('2.texts')}
                  choices={mypathSurveyQuestionsLexicon.get('2.choices')}
                  ariaHidden={isAnyModalVisible}
                  selectedChoiceIndex={surveyStateMP[2]}
                  isVertical
                  clickHandler={(choiceIndex: number) => {
                    answerHandlerMP(2, choiceIndex);
                  }}
                />
              )}
            </div>
          </>
        )}
        {pageNum === 1 && (
          <div className="page-2 survey-questions-container">
            {surveyStateMP[2] !== 3 && (
              <SingleRadioSurveyQuestion
                questionIndex={3}
                texts={mypathSurveyQuestionsLexicon.get('3.texts')}
                choices={mypathSurveyQuestionsLexicon.get('3.choices')}
                ariaHidden={isAnyModalVisible}
                selectedChoiceIndex={surveyStateMP[3]}
                isVertical
                clickHandler={(choiceIndex: number) => {
                  answerHandlerMP(3, choiceIndex);
                }}
              />
            )}
            {(isQuestionFullyAnsweredFuncMP(surveyStateMP[3]) ||
              surveyStateMP[2] === 3) && (
              <SingleRadioSurveyQuestion
                questionIndex={4}
                texts={mypathSurveyQuestionsLexicon.get('4.texts')}
                choices={mypathSurveyQuestionsLexicon.get('4.choices')}
                ariaHidden={isAnyModalVisible}
                selectedChoiceIndex={surveyStateMP[4]}
                isVertical
                clickHandler={(choiceIndex: number) => {
                  answerHandlerMP(4, choiceIndex);
                }}
              />
            )}
          </div>
        )}
        {pageNum === 2 && (
          <div className="page-3 survey-questions-container">
            <SingleRadioSurveyQuestion
              questionIndex={5}
              texts={mypathSurveyQuestionsLexicon.get('5.texts')}
              choices={mypathSurveyQuestionsLexicon.get('5.choices')}
              ariaHidden={isAnyModalVisible}
              selectedChoiceIndex={surveyStateMP[5]}
              isVertical
              clickHandler={(choiceIndex: number) => {
                answerHandlerMP(5, choiceIndex);
              }}
            />
            {isQuestionFullyAnsweredFuncMP(surveyStateMP[5]) && (
              <SingleRadioSurveyQuestion
                questionIndex={6}
                texts={mypathSurveyQuestionsLexicon.get('6.texts')}
                choices={mypathSurveyQuestionsLexicon.get('6.choices')}
                ariaHidden={isAnyModalVisible}
                selectedChoiceIndex={surveyStateMP[6]}
                isVertical
                clickHandler={(choiceIndex: number): void =>
                  answerHandlerMP(6, choiceIndex)
                }
              />
            )}
          </div>
        )}

        <div className="nav-buttons-container">
          <SmartNavigation
            type={backNavigationType}
            direction="back"
            text={sharedLexicon.get('navigation.backText')}
            fakeClickHandler={this.fakeBackClickHandler}
            realLinkTo={`/${locale}/${navLinks[0]}`}
            isHidden={isAnyModalVisible}
          />

          <Note sharedLexicon={sharedLexicon} />

          <SmartNavigation
            type={nextNavigationType}
            direction="next"
            text={sharedLexicon.get('navigation.nextText')}
            fakeClickHandler={this.fakeNextClickHandler}
            realLinkTo={`/${locale}/${navLinks[0]}`}
            isHidden={isNextButtonHidden || isAnyModalVisible}
            isNextButtonHidden={isNextButtonHidden}
          />
        </div>
      </>
    );
  }
}

export const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMP,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMP,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyMP);
