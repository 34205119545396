// @flow

import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import NavContainer from '../../shared/nav/NavContainer';
import SurveyActions from '../../../actions/SurveyActions';
import '../../shared/survey/SurveyPage.scss';
import './FertilitySurvey.scss';
import type { SurveyMPProps, State, MapStateToProps } from '../../../lib/types';

const FertilitySurvey: React.FC = ({
  locale,
  lexicon,
  sharedLexicon,
  isAnyModalVisible,
  surveyStateMP,
  answerSurveySingleMP,
  surveyLog,
  navLinks,
}: SurveyMPProps) => {
  const externalHeadingRef = useRef();

  const mypathSurveyQuestionsLexicon = sharedLexicon.subset('mypathSurveyQuestions');

  const clickHandler = (choiceIdx: number) => {
    answerSurveySingleMP(8, choiceIdx);
    surveyLog(mypathSurveyQuestionsLexicon.get('8.texts'), mypathSurveyQuestionsLexicon.get(`8.choices.${choiceIdx}`), window.location.pathname);
  };

  return (
    <>
      <Headings str={lexicon.get('headings')} externalHeading1Ref={externalHeadingRef} ariaHidden={isAnyModalVisible} />
      <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
      <div className="background-container">
        <div className="survey-questions-container">
          <SingleRadioSurveyQuestion
            questionIndex={0}
            texts={mypathSurveyQuestionsLexicon.get('8.texts')}
            choices={mypathSurveyQuestionsLexicon.get('8.choices')}
            ariaHidden={isAnyModalVisible}
            selectedChoiceIndex={surveyStateMP[8]}
            clickHandler={clickHandler}
            isVertical
          />
        </div>
      </div>
      <NavContainer
        locale={locale}
        backPage={navLinks[0]}
        nextPage={navLinks[1]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

export const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMP,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMP,
  isAnyModalVisible,
});
const mapDispatchToProps = SurveyActions;

export default connect(mapStateToProps, mapDispatchToProps)(FertilitySurvey);
