// @flow

import React from 'react';
import { connect } from 'react-redux';
import UCSF from './ucsf-no-text@2x.png';
import PTBI from './PTBI.png';
import PCRHP from './PCRHP.png';
import './UCSFtoolsFooter.scss';
import { isMyBCPeripartum } from '../../../lib/appMode';
import type { State } from '../../../lib/types';

type FooterProps = {
  id: string,
  userId: null | number,
};

const Footer = ({ id, userId }: FooterProps): React.Element<'footer'> => (
  <footer className="mbc-footer" id={id}>
    <img className="ucsf" src={UCSF} alt="UCSF - University of California, San Francisco" />
    <img className="pcrhp" src={PCRHP} alt="Person-Centered Reproductive Health Program" />
    { isMyBCPeripartum && (
      <img className="ptbi" src={PTBI} alt="California - Preterm Birth Initiative" />
    )}

    { userId && (
      <div className="user-id">ID: {userId}</div>
    )}
  </footer>
);

const mapStateToProps = ({ userId }: State): {} => ({ userId });

export default connect(mapStateToProps)(Footer);
