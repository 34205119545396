// @flow

import React from 'react';

type Props = {
  methods: Array<string>,
  title: string,
  noMethods: string,
  methodNames: Object,
  locale: 'en' | 'es',
};

const Methods = ({
  methods,
  methodNames,
  title,
  locale,
  noMethods,
}: Props): React.Element<'div'> => {
  return (
    <div className="methods">
      <h2>{title}</h2>

      {methods.length > 0 ? (
        <ul>
          {methods.map((method: string): React.Element<'li'> => (
            <li key={method} className={`${method} ${locale}`}>
              {methodNames[method]}
            </li>
          ))}
        </ul>
      ) : (
        <p className="empty">» {noMethods}</p>
      )}
    </div>
  );
};

export default Methods;
