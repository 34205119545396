// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import afterBabyActions from '../../../actions/afterBabyActions';
import type { PageProps, State } from '../../../lib/types';
import './Breastfeeding.scss';

type BreastfeedingProps = PageProps & {
  afterBabyTopics: {},
  navLinks: Array<string>,
};

class Breastfeeding extends React.Component<BreastfeedingProps, State> {
  constructor(props: BreastfeedingProps) {
    super(props);
    this.state = { visibleModal: null };
  }

  menuItemRef = (c: HTMLButtonElement) => { this._menuItem = c; }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    this.setState({ visibleModal: null });
  }

  render() {
    const { locale, afterBabyTopics, isAnyModalVisible, lexicon, sharedLexicon } = this.props;
    const { visibleModal } = this.state;

    const menuItemProps = (item: string): {} => ({
      question: lexicon.get(`${item}_topic`),
      modalCopy: lexicon.get(`${item}_modal_copy`),
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: () => { this.showModal(item); },
      onModalHide: this.hideModal,
      visited: !!afterBabyTopics[item],
      buttonText: lexicon.get('buttonText'),
      sharedLexicon,
      locale,
    });

    return (
      <>
        <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
        <div className="flex-group">
          <div className="image-space" />
          <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
          <MenuItem {...menuItemProps('breastfeeding_resources')} className="first" />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  afterBabyTopics,
  isAnyModalVisible,
}: StateType): State => ({
  afterBabyTopics,
  isAnyModalVisible,
});

const mapDispatchToProps = afterBabyActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breastfeeding);
