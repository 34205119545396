// @flow

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { Lexicon } from '@nitidbit/lexicon';

type LocalizedHelmetProps = {
  fullLexicon: Lexicon,
};

const titleGenerator = (pathname: string, lexicon: Lexicon): string => {
  const pageTitleSanitizer = (str: string): string => str.replace(/#|\\n/gi, '');

  const pageKey = pathname.slice(pathname.lastIndexOf('/') + 1) || 'splash';
  if (pageKey === 'splash') return process.env.REACT_APP_WEBSITE_NAME;
  // console.log('inside TG ', pathname, pageKey)
  const locale = pathname.startsWith('/es/') ? 'es' : 'en';
  const pageHeading = lexicon.locale(locale).get(`${pageKey}.headings`);
  const pageTitle = pageTitleSanitizer(pageHeading);
  // console.log(locale, pageTitle)
  return `${process.env.REACT_APP_WEBSITE_NAME}-${pageTitle}`;
};

const LocalizedHelmet: React.FC = ({ fullLexicon }: LocalizedHelmetProps) => {
  const { pathname } = useLocation();
  const locale = pathname.startsWith('/es/') ? 'es' : 'en';
  const title = titleGenerator(pathname, fullLexicon.locale(locale));

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default LocalizedHelmet;
