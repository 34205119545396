// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Lexicon } from '@nitidbit/lexicon';
import ModalWithOverlay from '../modal/ModalWithOverlay';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import { isInControl } from '../../../lib/appMode';
import './EmailModal.scss';

type EmailModalProps = {
  submitHandler: * => void,
  emailInputHandler: * => void,
  toggleInControlHandler: * => void,
  email: string,
  isShowingEmailErrorMessage: boolean,
  isShowingEmailSuccessMessage: boolean,
  isSendEmailButtonDisabled: boolean,
  closeModalHandler: * => void,
  isModalOn: boolean,
  emailModalLexicon: Lexicon,

  closeBox: string,
  modalHeadingRef: (c: ?HTMLHeadingElement) => void,
  modalWarningRef: (c: ?HTMLParagraphElement) => void,
};

const EmailModal = ({
  submitHandler,
  emailInputHandler,
  email,
  isSendEmailButtonDisabled,
  isShowingEmailErrorMessage,
  isShowingEmailSuccessMessage,
  closeModalHandler,
  isModalOn,
  closeBox,
  modalHeadingRef,
  modalWarningRef,
  emailModalLexicon,
  toggleInControlHandler = null
}: EmailModalProps): React.HTMLFormElement => {
  let emailInputClassName = '';
  if (isShowingEmailErrorMessage) emailInputClassName = 'error';
  else if (isShowingEmailSuccessMessage) emailInputClassName = 'success';

  return (
    <ModalWithOverlay
      clickHandler={closeModalHandler}
      isModalOn={isModalOn}
      id="email-modal"
      closeBox={closeBox}
    >
      <form onSubmit={submitHandler}>
        <div className="modal-content">
          <label id="email-modal-modal-title" htmlFor="email" ref={modalHeadingRef} tabIndex="-1">{emailModalLexicon.get('emailIntro')}</label>
          <input
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={emailInputHandler}
            placeholder={emailModalLexicon.get('emailPlaceholder')}
            className={emailInputClassName}
          />

          { isInControl && (
            <div className="flex-group">
              <input type="checkbox" id="navigator" name="send_to_navigator" onChange={toggleInControlHandler} />
              <label htmlFor="navigator">
                {emailModalLexicon.get('navigatorCheckbox')}
              </label>
            </div>
          )}

          <div className="message">
            {isShowingEmailErrorMessage && (
              <p className="error focus-start" ref={modalWarningRef} tabIndex="-1">{emailModalLexicon.get('emailError')}</p>
            )}
            {isShowingEmailSuccessMessage && (
              <p className="success focus-start" ref={modalWarningRef} tabIndex="-1">{emailModalLexicon.get('emailSent')}</p>
            )}
          </div>

          <div className="nav-buttons-container">
            <button
              type="submit"
              disabled={isSendEmailButtonDisabled}
              className={`nav-button ${isSendEmailButtonDisabled ? 'disabled' : ''}`}
            >
              {emailModalLexicon.get('sendEmail')}
            </button>
          </div>
        </div>
      </form>
    </ModalWithOverlay>
  );
};

const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(null, mapDispatchToProps)(EmailModal);
