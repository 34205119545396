// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import ConditionalTableTab from './ConditionalTableTab';
import { TD } from '../../shared/textComponents/TextComponents';
import { isMyBCPeripartum } from '../../../lib/appMode';

type TableProps = {
  lexicon: Lexicon,
  locale: string,
  methods: Array<number | null>,
  isAriaHiddenOn: boolean,
  children: React.childArray<ConditionalTableTab>,
};

const myBCtableConfig = [
  'preventing_pregnancy',
  'how_to_use',
  'how_often',
  'side_effects',
  'benefits',
  'do_not_use'
];

const tableConfig = isMyBCPeripartum
  ? [...myBCtableConfig, 'after_baby']
  : myBCtableConfig;

export const RegularTable = ({
  locale,
  lexicon,
  methods,
  isAriaHiddenOn,
  children
}: TableProps): React.Element<'table'> => {
  const categories = lexicon.get('categories');

  return (
    <table className="regular compare-table" aria-hidden={isAriaHiddenOn}>
      <thead>
        <tr>
          <th className="table-tab" scope="col" id="blank">{lexicon.get('categoriesText')}</th>
          {children}
        </tr>
      </thead>
      <tbody>
        {
          tableConfig.map((rowName: string, index: number): React.Element<'tr'> => {
            const tdStr1 = methods[0] === null ? null : lexicon.get(`${rowName}.${methods[0]}`);
            const tdStr2 = methods[1] === null ? null : lexicon.get(`${rowName}.${methods[1]}`);
            // if (tdStr1 === undefined) console.log('tdstr1', rowName, 'tdStr1', tdStr1, 'method', methods[0]);
            const tableRowID = categories[index];
            // console.log('tdStr1', tdStr1)

            return (
              <tr className="non-tabs" key={rowName} >
                {/* <TH id={tableRowID} str={categories[index]} /> */}
                <th className="table-row" id={`regular-${tableRowID}`} scope="row">
                  {categories[index]}
                </th>
                <TD str={tdStr1} locale={locale} />
                <TD str={tdStr2} locale={locale} />
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export const MobileTable = ({
  locale,
  lexicon,
  methods,
  isAriaHiddenOn,
  children
}: TableProps): React.Element<'table'> => {
  const categories = lexicon.get('categories');

  return (
    <table className="mobile compare-table" aria-hidden={isAriaHiddenOn}>
      <tbody>
        <tr>
          {children}
        </tr>
        {
          tableConfig.map((rowName: string, index: number): React.Fragment => {
            const tdStr1 = methods[0] === null ? null : lexicon.get(`${rowName}.${methods[0]}`);
            const tdStr2 = methods[1] === null ? null : lexicon.get(`${rowName}.${methods[1]}`);
            const tableRowID = categories[index];
            const extraClass = methods[0] === null && methods[1] === null ? 'inactive' : null;
            // console.log('tdStr1', tdStr1)

            return (
              <React.Fragment key={rowName}>
                <tr className={extraClass}>
                  <th className="table-row" colSpan="2" id={`mobile-${tableRowID}`}>{categories[index]}</th>
                </tr>
                <tr className={`non-tabs ${extraClass}`} >
                  <TD str={tdStr1} locale={locale} />
                  <TD str={tdStr2} locale={locale} />
                </tr>
              </React.Fragment>
            );
          })
        }
      </tbody>
    </table>
  );
};
