// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './Instruction.scss';
import type { State } from '../../../lib/types';

type Props = {
  onCloseClick: () => void,
  isAnyModalVisible: boolean,
  visible: boolean,
  header: ?string,
  body: string,
  isTopicInstruction: boolean,
  isSummaryReminder: boolean,
};

type Func = () => void;

const Instruction = ({
  visible = true,
  header,
  body,
  onCloseClick,
  isAnyModalVisible,
  isTopicInstruction,
  isSummaryReminder
}: Props): null | React.Element<'div'> => {
  useEffect((): Func => (() => { if (isTopicInstruction) onCloseClick(); }), []);

  if (!visible) return null;

  const instructionClassName = (
    // eslint-disable-next-line no-nested-ternary
    isTopicInstruction
      ? 'topic-modal'
      : isSummaryReminder
        ? 'summary-reminder'
        : null
  );

  let tabIndex;
  if (isTopicInstruction) tabIndex = '0';
  else tabIndex = isAnyModalVisible ? null : '0';

  let disabled;
  if (isTopicInstruction) disabled = false;
  else disabled = isAnyModalVisible;

  return (
    <div id="instructions-container" className={instructionClassName} aria-hidden>
      <div className="instructions-head">{header}</div>
      <div className="instructions-body">{body}</div>
      <div className="instructions-arrow" />
      <button
        disabled={disabled}
        className="instructions-close"
        onClick={onCloseClick}
        tabIndex={tabIndex}
        type="button"
      />
    </div>
  );
};

const mapStateToProps = ({ isAnyModalVisible }: State): {} => ({ isAnyModalVisible });

export default connect(mapStateToProps)(Instruction);
