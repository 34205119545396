// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import Topic from '../../shared/topic/Topic';
import markdown from '../../../lib/utils';

type SectionType = string | Array<string>;

type SectionsType = {
  item: string,
  sections: [SectionType],
  ariaHidden: boolean,
  locale: 'en' | 'es',
};

type SectionReturnType = React.Element<*> | Array<React.Element<*>> | null;

type Props = {
  sharedLexicon: Lexicon,
  item: string,
  label: string,
  sections: [ SectionType ],
  visited: boolean,
  modalVisible: boolean,
  onModalShow: () => null,
  onModalHide: () => null,
  isAnyModalVisible: boolean,
  locale: 'en' | 'es',
};

const Sections = ({ sections, item, ariaHidden, locale }: SectionsType): [SectionReturnType] => {
  let isInstructionAvailable = true;

  return sections.map((section: SectionType): SectionReturnType => {
    if (typeof section === 'string') {
      return <div key={section} id={`${item}-modal-body`} dangerouslySetInnerHTML={{ __html: markdown(section) }} />;
    }

    if (Array.isArray(section)) {
      if (isInstructionAvailable) {
        isInstructionAvailable = false;
        return section.map((topic: string, i: number): React.Element<*> => (
          <Topic
            key={topic}
            topic={topic}
            locale={locale}
            ariaHidden={ariaHidden}
            isFirst={i === 0}
          />
        ));
      }

      return section.map((topic: string): React.Element<*> => (
        <Topic
          key={topic}
          topic={topic}
          locale={locale}
          ariaHidden={ariaHidden}
        />
      ));
    }

    return null;
  });
};

class HealthPregnancyMenuItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.modalHeadingRef = React.createRef();
    this.buttonRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props) {
    const { modalVisible } = this.props;
    if (!prevProps.modalVisible && modalVisible) {
      this.modalHeadingRef.current.focus();
    }
    if (prevProps.modalVisible && !modalVisible) {
      this.buttonRef.current.focus();
    }
  }

  render() {
    const {
      item,
      label,
      visited,
      sections,
      sharedLexicon,
      onModalShow,
      onModalHide,
      modalVisible,
      isAnyModalVisible,
      locale,
    } = this.props;

    return (
      <div className={`item ${item}`}>
        <button
          ref={this.buttonRef}
          className="item-button"
          type="button"
          onClick={onModalShow}
          aria-hidden={isAnyModalVisible}
          disabled={isAnyModalVisible}
          tabIndex="0"
        >
          <span className="image" />
          {visited && <span className="visited-overlay-image" />}
          {label}
          <span className="visually-hidden">
            {visited ? ', already viewed' : ', not yet viewed'}
          </span>
        </button>

        <ModalWithOverlay
          id={item}
          isModalOn={modalVisible}
          clickHandler={onModalHide}
          closeBox={sharedLexicon.get('closeBox')}
        >
          <header className="rainbow mobile">
            <div className="yellow-box box" />
            <div className="red-box box" />
            <div className="lime-box box" />
            <div className="green-box box" />
            <div className="blue-box box" />
            <div className="indigo-box box" />
          </header>
          <div className="modal-content">
            <h1 id={`${item}-modal-title`} ref={this.modalHeadingRef} tabIndex="-1" className="heading focus-start"><span />{label}</h1>
            {
              modalVisible && (
                <Sections
                  sections={sections}
                  item={item}
                  ariaHidden={!isAnyModalVisible || !modalVisible}
                  locale={locale}
                />
              )
            }
          </div>
          <div className="nav-buttons-container fake mobile">
            <button onClick={onModalHide} type="button" className="nav-button left">« {sharedLexicon.get('navigation.backText')}</button>
          </div>
        </ModalWithOverlay>
      </div>
    );
  }
}

export default HealthPregnancyMenuItem;
