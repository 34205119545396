// @flow

import { Lexicon } from '@nitidbit/lexicon';
import { isMyPathVA, isMyPathGeneral, isMyBCPeripartum, isMyPathPeripartum, isMyPathPeripartumVA, isInControl } from './appMode';
import mypathVaStrings from '../strings/mypathVA.json';
import mypathGeneralStrings from '../strings/mypathGeneral.json';
import peripartumStrings from '../strings/peripartum.json';
import mybcStrings from '../strings/mybc.json';
import mypathPeripartumGeneralStrings from '../strings/mypathPeripartumGeneral.json';
import mypathPeripartumVaStrings from '../strings/mypathPeripartumVA.json';
import inControlStrings from '../strings/inControl.json';

let stringFileSource;

if (isMyPathPeripartumVA) stringFileSource = 'client/src/strings/mypathPeripartumVA.json';
else if (isMyPathVA) stringFileSource = 'client/src/strings/mypathVA.json';
else if (isMyPathPeripartum) stringFileSource = 'client/src/strings/mypathPeripartumGeneral.json';
else if (isMyPathGeneral) stringFileSource = 'client/src/strings/mypathGeneral.json';
else if (isMyBCPeripartum) stringFileSource = 'client/src/strings/peripartum.json';
else if (isInControl) stringFileSource = 'client/src/strings/inControl.json';
else stringFileSource = 'client/src/strings/mybc.json';

let rawLexicon;

if (isMyPathPeripartumVA) rawLexicon = mypathPeripartumVaStrings;
else if (isMyPathVA) rawLexicon = mypathVaStrings;
else if (isMyPathPeripartum) rawLexicon = mypathPeripartumGeneralStrings;
else if (isMyPathGeneral) rawLexicon = mypathGeneralStrings;
else if (isMyBCPeripartum) rawLexicon = peripartumStrings;
else if (isInControl) rawLexicon = inControlStrings;
else rawLexicon = mybcStrings;

export const pagesLexicon = new Lexicon(rawLexicon, 'en', stringFileSource);
