/* eslint-disable react/no-unused-prop-types */
// @flow

import React, { useEffect, useRef, createRef } from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import markdown, { MarkdownSyntaxRemoverFunc, hyphenReplacerFunc } from '../../lib/utils';

type BracketProps = { rangeArr: Array<string> };

type sharedBarProps = {
  iconNameArr: Array<string>,
  selectedIconNum: number | null,
  sharedLexicon: Lexicon,
};

type IconBarProps = sharedBarProps & {
  clickHandler: * => void,
  isAnyModalVisible: boolean,
  screenReaderHeaderInsertionConfig: [number],
  children: [React.Element<'h3'>],
};
type ResultBarProps = sharedBarProps & {
  resultArr: Array<string>,
};
type MobileOnlyBracketsAndIconBarsProps = IconBarProps & {
  rangeArr: Array<string>,
  mobileConfig: Array<number>,
  isAnyModalVisible: boolean,
  mobileButtonRefs: [{}],
  resultArr: Array<string>,
  locale: string,
};

export const Brackets = ({ rangeArr }: BracketProps): React.Element<'div'> => (
  <div className="brackets-container" aria-hidden>
    {
      rangeArr.map((str: string, i: number): React.Element<'div'> => (
        <div className={`brackets bracket-${i}`} key={str}>
          <span>{str}</span>
        </div>
      ))
    }
  </div>
);

export const IconBar = ({ iconNameArr, selectedIconNum, clickHandler, isAnyModalVisible, screenReaderHeaderInsertionConfig = [], children }: IconBarProps): React.Element<'div'> => {
  const refs = useRef(new Array(iconNameArr.length).fill(0).map((): {} => createRef()));

  useEffect(() => {
    if (Number.isInteger(selectedIconNum)) refs.current[selectedIconNum].current.focus();
  }, [selectedIconNum]);

  const keyPressHandler = (evt: React.SyntheticEvent) => {
    const keyNum = (evt.which) ? evt.which : evt.keyCode;

    switch (keyNum) {
      case 39: // right
        if (selectedIconNum === iconNameArr.length - 1) clickHandler(0);
        else clickHandler(selectedIconNum + 1);
        break;
      case 37: // left
        if (selectedIconNum === 0) clickHandler(iconNameArr.length - 1);
        else clickHandler(selectedIconNum - 1);
        break;
      case 36: // home
        clickHandler(0);
        break;
      case 35: // end
        clickHandler(iconNameArr.length - 1);
        break;
      default:
        break;
    }
  };

  let screenReaderHeaderIndex = 0;

  return (
    <div className="bc-bar-items-container" role="tablist">
      {
        iconNameArr.map((iconName: string, i: number): React.Element<'div'> => {
          const extraClass = selectedIconNum === i ? 'selected' : '';
          const tabIndex = (isAnyModalVisible || selectedIconNum === i) ? null : '-1';

          const IconBarButton = (): React.Element<'button'> => (
            <button
              className={`bc-bar-item ${iconName} ${extraClass}`}
              key={iconName}
              onClick={() => { clickHandler(i); }}
              onKeyDown={keyPressHandler}
              aria-hidden={isAnyModalVisible}
              aria-label={iconName}
              tabIndex={tabIndex}
              role="tab"
              type="button"
              aria-selected={selectedIconNum === i}
              id={`${iconName}-tab`}
              ref={refs.current[i]}
            >
              {/* <div className="screen-reader-only">{ MarkdownSyntaxRemoverFunc(hyphenReplacerFunc(`${sharedLexicon.get(methodNames.iconNameArr[i])}: ${resultArr[i]}`, locale)) }</div> */}
            </button>
          );

          if (i === screenReaderHeaderInsertionConfig[screenReaderHeaderIndex]) {
            screenReaderHeaderIndex += 1;

            return (
              <>
                { children[screenReaderHeaderIndex - 1] }
                <IconBarButton />
              </>
            );
          }

          return <IconBarButton />;
        })
      }
    </div>
  );
};

export const ResultBar = ({ isAnyModalVisible, iconNameArr, resultArr, selectedIconNum, sharedLexicon }: ResultBarProps): React.Element<'div'> => (
  resultArr.map((result: string, i: number): React.Element<'div'> => {
    // console.log(text)
    const mappedIconName = sharedLexicon.get(`methodNames.${iconNameArr[i]}`);
    const text = `_\`${mappedIconName}:\`_ ${result}`;
    const resultBarClassName = `${i === selectedIconNum ? 'selected' : 'no-display'} bc-result-container`;
    const hiddenProp = i === selectedIconNum ? null : 'hidden';

    return (
      <div
        className={resultBarClassName}
        key={`${iconNameArr[i]}-${text}`}
        role="tabpanel"
        aria-labelledby={`${iconNameArr[i]}-tab`}
        hidden={hiddenProp}
        tabIndex={isAnyModalVisible ? null : '0'}
        dangerouslySetInnerHTML={{ __html: markdown(text) }}
      />
    );
  })
);

const arrSplitter = (arr: Array<*>, configArr: Array<number>): Array<Array<*>> => (
  configArr.map((v: number): Array<*> => arr.splice(0, v))
);

export const MobileBracketsAndIconBars = ({
  clickHandler,
  mobileConfig,
  rangeArr,
  iconNameArr,
  resultArr,
  sharedLexicon,
  isAnyModalVisible,
  mobileButtonRefs,
  locale,
}: MobileOnlyBracketsAndIconBarsProps): React.Element<'div'> => {
  const splitIconNameArr = arrSplitter(iconNameArr.slice(), mobileConfig);
  let counter = -1;

  return (
    <div className="bc-bars-and-brackets-mobile-container mobile">
      {
        rangeArr.map((range: string, i: number): React.Element<'div'> => (
          <div className={`bracket-and-bar-group bracket-and-bar-group-${i}`} key={range}>
            <div className={`brackets bracket-${i}`} aria-hidden>
              <span>{range}</span>
            </div>
            <div className="bc-bar-items-container">
              {
                splitIconNameArr[i].map((iconName: string): React.Element<'div'> => {
                  counter += 1;
                  const index = counter;
                  const mappedIconName = sharedLexicon.get(`methodNames.${iconName}`);

                  return (
                    <button
                      className={`bc-bar-item ${iconName}`}
                      key={iconName}
                      onClick={() => { clickHandler(index); }}
                      type="button"
                      disabled={isAnyModalVisible}
                      aria-hidden={isAnyModalVisible}
                      tabIndex={isAnyModalVisible ? null : '0'}
                      ref={mobileButtonRefs[index]}
                    >
                      <div className="screen-reader-only">{ MarkdownSyntaxRemoverFunc(hyphenReplacerFunc(`${mappedIconName}: ${resultArr[i]}`, locale)) }</div>
                    </button>
                  );
                })
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};
