// @flow

import React from 'react';
import Pie from './Pie';
import type { PageProps } from '../../../lib/types';
import './FertilityByAge.scss';

const config = [
  {
    // chance: '85',
    color: 'dark_blue',
    range: '19-34',
  },
  {
    // chance: '70',
    color: 'light_blue',
    range: '35-39',
  },
  {
    // chance: '50',
    color: 'light_green',
    range: '40-44',
  },
  {
    // chance: '5',
    color: 'orange',
    range: '45-50',
  },
];

// safe to use 'to' since this page is currently English only
const screenReaderSanitizer = (str: string): string => (str.replace(/<br \/>|<br\/>|<b>|<\/b>/gi, '').replace('-', ' to '));

type FertilityByAgeState = {
  selectedButton: number,
};

class FertilityByAge extends React.PureComponent<PageProps, FertilityByAgeState> {
  constructor(props: Props) {
    super(props);
    this.state = { selectedButton: 0 };
    this.propsForPie = this.propsForPie.bind(this);
  }

  handleChange(event: Object) {
    const pos = parseInt(event.target.getAttribute('position'), 10);
    this.setState({ selectedButton: pos });
  }

  propsForPie(): Object {
    const position = this.state.selectedButton;
    const props = config[position];

    // console.log(position);
    let keys = ['chance', 'prefix', 'postfix'];
    if (position === 3) keys = ['chance', 'prefix', 'postfix', 'displayChance'];

    keys.forEach((key: string) => {
      props[key] = this.props.lexicon.get(`${position}.${key}`);
    });

    // console.log(props);

    return props;
  }

  render() {
    const { lexicon, locale } = this.props;

    const tabs = (): React.Node => (
      config.map((pie: Object, idx: number): React.Element<'button'> => {
        const ariaLabel = screenReaderSanitizer(`${lexicon.get(`chart.${idx}.prefix`)} ${lexicon.get(`chart.${idx}.chance`)}% chance ${lexicon.get(`chart.${idx}.postfix`)}`);

        return (
          <button
            className={`${pie.color} ${idx === this.state.selectedButton && 'active'}`}
            onClick={(e: SyntheticEvent<HTMLButtonElement>) => { this.handleChange(e); }}
            position={idx}
            aria-pressed={idx === this.state.selectedButton}
            key={pie.range}
            type="button"
            aria-label={ariaLabel}
          >
            {pie.range}
          </button>
        );
      })
    );

    return (
      <section id="fertility-by-age">
        <fieldset className="button-bar">
          {tabs()}
        </fieldset>
        <Pie locale={locale} {...this.propsForPie()} ariaHidden />
      </section>
    );
  }
}

export default FertilityByAge;
