// @flow

import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';
import visitedStateMBC from './visitedReducerMBC';
import visitedStateMP from './visitedReducerMP';
import notesReducer from './notes_reducer';
import topicReducer from './topic_reducer';
import fertilityMenuReducer from './fertility_menu_reducer';
import healthPregnancyReducer from './health_pregnancy_reducer';
import sideEffectsCategoryVisitedArr from './sideEffectsCategoryVisitedReducer';
import surveyStateMP from './surveyReducerMP';
import surveyStateMBC from './surveyReducerMBC';
import activeRecs from './activeRecsReducer';
import currentMethods from './currentMethodsReducer';
import isAnyModalVisible from './isAnyModalVisibleReducer';
import afterBabyTopics from './afterBabyReducer';
import isShowingRecs from './showRecsReducer';
import userId from './userIdReducer';
import isShowingTopicModal from './topicModalReducer';
import isShowingSummaryReminder from './summaryReminderReducerMP';
import barrierSurvey from './barrierSurveyReducer';

import { isMyPath } from '../lib/appMode';
import type { State, Action } from '../lib/types';

type Reducer = (Action) => State;

let mainReducer = {
  visitedStateMBC,
  notesReducer,
  sideEffectsCategoryVisitedArr,
  surveyStateMBC,
  activeRecs,
  isAnyModalVisible,
  afterBabyTopics,
  isShowingRecs,
  userId,
  isShowingTopicModal,
  isShowingSummaryReminder,
  barrierSurvey,
};

if (isMyPath) {
  mainReducer = {
    ...mainReducer,
    visitedStateMP,
    topicReducer,
    fertilityMenuReducer,
    healthPregnancyReducer,
    surveyStateMP,
    currentMethods,
  };
}

const rootReducer = (state: State, action: {}): Reducer => {
  let newState = { ...state };
  if (action.type === PURGE) newState = undefined;

  return combineReducers(mainReducer)(newState, action);
};

export default rootReducer;
