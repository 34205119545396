// @flow

import React from 'react';

type IconButtonProps = {
  iconNameEnglish: string,
  iconName: string,
  isActive: boolean,
  clickHandler: (string) => void,
  newline?: boolean,
  children?: React.Node,
  ariaHidden: boolean,
  ariaText: string,
  sectionText: string,
};

const IconButton = ({
  locale,
  ariaText = '',
  ariaHidden,
  iconNameEnglish,
  iconName,
  isActive = false,
  clickHandler,
  children = null,
  newline = false,
  sectionText,
}: IconButtonProps): React.Element<'button'> => {
  const text = newline ? `${iconName}\n` : iconName;

  const extraClassArr = [locale];
  if (isActive) extraClassArr.push('active');
  const extraClass = extraClassArr.join(' ');

  const keyPressHandler = (evt: React.SyntheticEvent<HTMLSpanElement>) => {
    const keyNum = evt.which || evt.keyCode;

    if (keyNum === 13 || keyNum === 32) clickHandler(iconNameEnglish);
  };

  return (
    <div key={iconNameEnglish} className={`rec-icon-container ${extraClass}`}>
      <div
        aria-checked={isActive}
        role="checkbox"
        tabIndex={ariaHidden ? null : '0'}
        aria-label={`${text}, ${ariaText}`}
        onClick={() => {
          clickHandler(iconNameEnglish);
        }}
        onKeyDown={keyPressHandler}
        className={`icon ${iconNameEnglish}`}
        id={`${sectionText}-checkbox-${iconNameEnglish}-box`}
      />
      <label
        htmlFor={`checkbox-for-${iconNameEnglish}-box`}
        id={`checkbox-for-${iconNameEnglish}`}
        aria-hidden
      >
        {text}
        {children}
      </label>
    </div>
  );
};

IconButton.defaultProps = {
  children: null,
  newline: false,
};

export default IconButton;
