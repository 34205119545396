// @flow

import React from 'react';
import { connect } from 'react-redux';
import NavButton from '../../shared/nav/NavButton';
import {
  MarkdownBlock,
  Headings,
} from '../../shared/textComponents/TextComponents';
import { numOfQuestionToUnlockMainNavs } from '../../../reducers/surveyReducerMP';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import noteActions from '../../../actions/noteActions';
import type {
  State,
  MapStateToProps,
  SurveyStateMP,
  PageProps,
} from '../../../lib/types';
import '../../shared/main/MainPage.scss';
import './MainMP.scss';
import Instruction from '../../shared/note/Instruction';

type MainPropsMP = PageProps & {
  surveyStateMP: SurveyStateMP,
  visitedState: Array<boolean>,
  navigationVisibility: { visibility: boolean },
  setModalInvisible: () => void,
};

const isMainNavUnlocked = (arr: Array<null | number>): boolean => {
  if (numOfQuestionToUnlockMainNavs < 1) return true;
  if (arr[2] === 0) return true;
  if (arr[2] === 3 && typeof arr[4] === 'number' && typeof arr[5] === 'number')
    return true;
  if (
    arr.filter((v: undefined | number | null): boolean => typeof v === 'number')
      .length >= numOfQuestionToUnlockMainNavs
  )
    return true;
  return false;
};

class MainMP extends React.Component<MainPropsMP> {
  constructor(props: MainPropsMP) {
    super(props);
    this.headerRef = React.createRef();
    this.navRefs = props.lexicon
      .get('navs')
      .map((): Object => React.createRef());
  }

  componentDidMount() {
    const { visitedState, setModalInvisible } = this.props;
    if (visitedState.length === 0 && this.headerRef.currents) {
      this.headerRef.current.focus();
    } else if (this.navRefs[visitedState[visitedState.length - 1]]) {
      this.navRefs[visitedState[visitedState.length - 1]].current.focus();
    }
    setModalInvisible();
  }

  render() {
    const {
      lexicon,
      locale,
      visitedState,
      surveyStateMP,
      navLinks,
      isAnyModalVisible,
      isShowingSummaryReminder,
      hideSummaryReminder,
    } = this.props;

    let isShowingAllNavs = true;
    if (process.env.REACT_APP_MODE === 'mypathVAStudy') {
      isShowingAllNavs = isMainNavUnlocked(surveyStateMP);
    }

    return (
      <>
        <Headings
          externalHeading1Ref={this.headerRef}
          str={lexicon.get('headings')}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock
          str={lexicon.get('paragraphs')}
          ariaHidden={isAnyModalVisible}
        />
        <div className="main-nav-buttons-container" role="menu">
          {lexicon
            .get('navs')
            .map((nav: string, i: number): React$Element<*> | null => {
              if (i > 0 && !isShowingAllNavs) return null;

              return (
                <NavButton
                  str={nav}
                  to={`/${locale}/${navLinks[i]}`}
                  key={nav}
                  isMainNavMP
                  index={i}
                  isVisited={visitedState.includes(i)}
                  isHidden={isAnyModalVisible}
                  linkRef={this.navRefs[i]}
                />
              );
            })}

          <Instruction
            header={lexicon.get('summary_reminder.header')}
            body={lexicon.get('summary_reminder.body')}
            visible={isShowingSummaryReminder}
            onCloseClick={hideSummaryReminder}
            isSummaryReminder
          />
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<State> = ({
  visitedStateMP,
  surveyStateMP,
  isShowingSummaryReminder,
  isAnyModalVisible,
  userId,
}: State): Object => ({
  visitedState: visitedStateMP,
  surveyStateMP,
  isShowingSummaryReminder,
  isAnyModalVisible,
  userId,
});
const mapDispatchToProps = {
  hideSummaryReminder: (): {} => ({ type: 'SET_SUMMARY_REMINDER_HIDDEN' }),
  ...isAnyModalVisibleActions,
  ...noteActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMP);
