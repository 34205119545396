// @flow

import React from 'react';

type Props = {
  title: string,
  rows: Array<{ question: string, answer: string }>,
};

const Thoughts = ({ title, rows }: Props): React.Element<'div'> => {
  if (rows.every((row: string): boolean => !row.value)) return <div />;

  return (
    <div className="item questions">
      <h2>{title}</h2>
      <ul>
        {rows.map(({ category, value }: Props.rows): React.Element<'li'> => (
          value ? (
            <li key={category}>
              <div className="question">{category}</div>
              <div className="answer">{value}</div>
            </li>
          ) : null
        ))}
      </ul>
    </div>
  );
};

export default Thoughts;
