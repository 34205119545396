// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import HealthPregnancyMenuItem from './HealthPregnancyMenuItem';
import { isMyPathPeripartum } from '../../../lib/appMode';
import type { PageProps, State } from '../../../lib/types';
import './HealthPregnancyMenu.scss';

let menuItems;

if (isMyPathPeripartum) {
  menuItems = [
    'complicated_pregnancy',
    'medical_conditions',
    'mental_health',
    'relationships',
    'breastfeeding',
    'healthy_lifestyle',
    'your_body',
    'infections',
    'substances',
    'medications',
  ];
} else {
  menuItems = [
    'healthy_lifestyle',
    'medical_conditions',
    'mental_health',
    'medications',
    'relationships',
    'spacing_between_babies',
    'your_body',
    'infections',
    'vaccines',
    'substances',
  ];
}

type HealthPregnancyMenuState = {
  visibleModal: string,
  isAnyModalVisible: boolean,
};

type Props = PageProps & {
  visitedItems: Array<string>,
  onModalShow: (item: string) => {},
  onModalHide: () => {},
};

class HealthPregnancyMenu extends React.Component<Props, HealthPregnancyMenuState> {
  constructor(props: Props) {
    super(props);
    this.state = { visibleModal: null };
  }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    const { onModalHide } = this.props;
    this.setState({ visibleModal: null });
    if (onModalHide) { onModalHide(); }
  }

  render() {
    const { visitedItems, isAnyModalVisible, lexicon, sharedLexicon, locale } = this.props;
    const { visibleModal } = this.state;

    return (
      <>
        <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
        <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
        <div className="items-container">
          {
            menuItems.map((key: string): Array<HealthPregnancyMenuItem> => (
              <HealthPregnancyMenuItem
                key={lexicon.get(`${key}.label`)}
                item={key}
                label={lexicon.get(`${key}.label`)}
                sections={lexicon.get(`${key}.sections`)}
                sharedLexicon={sharedLexicon}
                modalVisible={visibleModal === key}
                isAnyModalVisible={isAnyModalVisible}
                visited={visitedItems.includes(key)}
                onModalShow={(): void => this.showModal(key)}
                onModalHide={this.hideModal}
                locale={locale}
              />
            ))
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: State): StateType => ({
  visitedItems: state.healthPregnancyReducer.visited,
  isAnyModalVisible: state.isAnyModalVisible
});

const mapDispatchToProps = (dispatch: Dispatch): Object => ({
  onModalShow: (item: string): null => {
    dispatch({ type: 'SET_HEALTH_PREGNANCY_ITEM_VISITED', item });
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: true });
  },
  onModalHide: (): null => {
    dispatch({ type: 'SET_IS_ANY_MODAL_VISIBLE', visibility: false });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthPregnancyMenu);
