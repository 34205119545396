// @flow

import React from 'react';
import { connect } from 'react-redux';
import Instruction from '../note/Instruction';
import { isMyPath } from '../../../lib/appMode';
import type { Action, State } from '../../../lib/types';
import './Topic.scss';

type Props = {
  selected: boolean,
  topic: string,
  shortForm: boolean, // true = don't include the sentence around topic, e.g. "added to my summary"
  onClick: () => void,
  hideInstructions: () => void,
  ariaHidden?: boolean,
  isFirst: boolean,
  isShowingTopicModal: boolean,
  locale: 'en' | 'es',
};

const Topic = ({
  topic,
  selected,
  shortForm,
  hideInstructions,
  ariaHidden = false,
  isFirst,
  isShowingTopicModal,
  onClick,
  locale,
}: Props): React.Element<'div'> => {
  let before = '';
  let after = '';
  if (!shortForm) {
    if (selected) {
      after = locale === 'es' ? 'añadido a mi resumen' : 'added to my summary';
    } else {
      before = locale === 'es' ? 'Añadir' : 'Add';
      after = locale === 'es' ? 'a mi resumen' : 'to my summary';
    }
  }

  const body =
    locale === 'es'
      ? 'Toca para añadir esto a tu página de resumen para discutirlo con tu proveedor.'
      : 'Tap to add this to your summary page to discuss with your provider.';

  const clickTopic = () => {
    onClick(topic, selected);
  };

  const isShowingTopicInstructions = isMyPath && isShowingTopicModal && isFirst;

  return (
    <div className={`topic-container ${selected ? 'selected' : ''}`}>
      {isShowingTopicInstructions && (
        <Instruction
          body={body}
          key="instructions"
          onCloseClick={hideInstructions}
          visible
          isTopicInstruction
        />
      )}
      <button
        aria-checked={selected}
        role="checkbox"
        tabIndex={ariaHidden ? null : '0'}
        aria-hidden={ariaHidden}
        aria-label={`${before} ${topic} ${after}`}
        className="topic-button"
        onClick={clickTopic}
        type="button"
      >
        {before} <em>{topic}</em> {after}
      </button>
    </div>
  );
};

const mapStateToProps = (state: State, ownProps: Props): TopicState => ({
  selected: state.topicReducer.topics.includes(ownProps.topic),
  isShowingTopicModal: state.isShowingTopicModal,
});

const mapDispatchToProps = {
  onClick: (topic: string): Action => ({ type: 'TOGGLE_TOPIC', topic }),
  hideInstructions: (): Action => ({ type: 'HIDE_TOPIC_MODAL' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Topic);
