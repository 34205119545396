// @flow
import { Middleware, Dispatch } from 'redux';
import type { Store, Action } from './types';
import { recordUserAnalytics } from './utils';

export const reduxAnalytics: Middleware = (store: Store) => (next: Dispatch) => (action: Action) => {
  let entry = null;
  const { userId } = store.getState();
  const timestamp = new Date().toISOString();

  switch (action.type) {
    case 'SET_NOTES':
      entry = {
        timestamp,
        interaction: action.type,
        notes: action.notes
      };
      break;
    case 'TOGGLE_TOPIC':
      entry = {
        timestamp,
        interaction: action.type,
        topic: action.topic
      };
      break;
    case 'NAV_CLICK':
      entry = {
        timestamp,
        path: action.path,
        interaction: action.type,
        label: action.label
      };
      break;
    case 'MODAL_BUTTON_CLICK':
      entry = {
        timestamp,
        path: action.path,
        interaction: action.type,
        label: action.label
      };
      break;
    case 'SURVEY_CLICK':
      entry = {
        timestamp,
        path: action.path,
        interaction: action.type,
        question: action.question,
        answer: action.answer
      }
      break;
    default:
      entry = null;
  }

  if (entry) recordUserAnalytics(userId, entry);

  return next(action);
}