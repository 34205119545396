// @flow
import type { Action, BarrierSurveyState } from '../lib/types';

type SurveyAction = Action & { index: number };

const initialState = new Array(9).fill(0);
const prefilledInitialState = new Array(9).fill(2);

const barrierSurveyReducer = (state: BarrierSurveyState = initialState, action: SurveyAction): BarrierSurveyState => {
  const newAnswers = state.slice();

  switch (action.type) {
    case 'ANSWER_BARRIER_SURVEY':
      newAnswers[action.index] = action.value;

      return newAnswers;
    case 'SECRET_PREFILL_SURVEY':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default barrierSurveyReducer;
