// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import { persistor } from '../../../lib/store';
import userIdActions from '../../../actions/userIdActions';
import type { PageProps } from '../../../lib/types';

const StartMP = ({ setUserId }: PageProps): React.Element<*> => {
  const { friendly_id } = useParams();

  const [isPurgingDone, setIsPurgingDone] = useState(false);

  useEffect(async () => {
    await persistor.purge();
    setIsPurgingDone(true);
  }, []);

  if (!isPurgingDone) return <h1>Please Wait</h1>;

  setUserId(friendly_id);
  return <Navigate to="/splash" />;
};

const mapDispatchToProps = userIdActions;

export default connect(null, mapDispatchToProps)(StartMP);
