// @flow

import React from 'react';
import { connect } from 'react-redux';
import RecSectionWithRedux from './RecSection';
import { RegularRibbon } from '../../shared/ribbon/Ribbon';
import ConditionalNav from './ConditionalNav';
import {
  Headings,
  MarkdownBlock,
} from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import NavButton from '../../shared/nav/NavButton';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps, State } from '../../../lib/types';

import './Recommendations.scss';

type RecommendationsProps = PageProps & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

type RecommendationsState = {
  isShowingRecs: boolean,
  isSourcesModalOn: boolean,
  isNoMethodModalOn: boolean,
  activeIconName: null | string,
  activeIconSection: string,
};

export const arrowConfig = {
  effectivenessAndTiming: 'info-box-up-arrow',
  frequencyAndAdmin: 'info-box-up-arrow',
  benefitAndSideEffect: 'info-box-down-arrow',
  healthConcerns: 'info-box-down-arrow',
};

export const sectionOrderArr = [
  'effectivenessAndTiming',
  'frequencyAndAdmin',
  'benefitAndSideEffect',
  'healthConcerns',
];

class Recommendations extends React.Component<
  RecommendationsProps,
  RecommendationsState,
> {
  _modalHeading: HTMLHeadingElement;

  constructor(props: RecommendationsProps) {
    super(props);
    this.state = {
      isSourcesModalOn: false,
      isNoMethodModalOn: false,
      activeIconName: '',
      activeIconSection: '',
    };
  }

  componentDidMount() {
    this.props.setModalInvisible();
  }

  modalHeadingRef = (c: ?HTMLHeadingElement) => {
    this._modalHeading = c;
  };
  sourcesButtonRef = (c: ?HTMLButtonElement) => {
    this._sourcesButton = c;
  };
  noMethodContentRef = (c: ?HTMLDivElement) => {
    this._noMethodContent = c;
  };
  noMethodButtonRef = (c: ?HTMLButtonElement) => {
    this._noMethodButton = c;
  };

  sourcesModalClickHandler = () => {
    const { isSourcesModalOn } = this.state;
    const { setModalInvisible, setModalVisible } = this.props;
    if (isSourcesModalOn) {
      setModalInvisible();
      this.setState({ isSourcesModalOn: false }, () => {
        this._sourcesButton.focus();
      });
    } else {
      setModalVisible();
      this.setState({ isSourcesModalOn: true }, () => {
        this._modalHeading.focus();
      });
    }
  };

  noMethodModalClickHandler = () => {
    const { isNoMethodModalOn } = this.state;
    const { setModalInvisible, setModalVisible } = this.props;
    if (isNoMethodModalOn) {
      setModalInvisible();
      this.setState({ isNoMethodModalOn: false }, () => {
        this._noMethodButton.focus();
      });
    } else {
      setModalVisible();
      this.setState({ isNoMethodModalOn: true }, () => {
        this._noMethodContent.focus();
      });
    }
  };

  floatingBoxClickHandler = (
    activeIconName: string = '',
    activeIconSection: string = ''
  ) => {
    this.setState({ activeIconName, activeIconSection });
  };

  render() {
    const {
      isSourcesModalOn,
      isNoMethodModalOn,
      activeIconName,
      activeIconSection,
    } = this.state;
    const {
      locale,
      navLinks,
      showInfoClickHandler,
      isShowingRecs,
      isAnyModalVisible,
      setModalInvisible,
      lexicon,
      sharedLexicon,
    } = this.props;

    const keyPressHandler = (evt: React.SyntheticEvent) => {
      const keyNum = evt.which ? evt.which : evt.keyCode;

      switch (keyNum) {
        case 32: // space
          setModalInvisible();
          break;
        case 13: // enter
          setModalInvisible();
          break;
        default:
          break;
      }
    };

    return (
      <>
        <Headings
          ariaHidden={isAnyModalVisible}
          isAutoFocusing
          str={lexicon.get('headings')}
        />
        <MarkdownBlock
          str={lexicon.get('paragraphs')}
          ariaHidden={isAnyModalVisible}
        />
        {!isShowingRecs ? (
          <button
            className="recs-toggle"
            type="button"
            onClick={showInfoClickHandler}
          >
            {lexicon.get('buttonText')}
          </button>
        ) : (
          <>
            <RegularRibbon
              str={sharedLexicon.get('navigation.compareMethodsText')}
              to={`/${locale}/${navLinks[0]}`}
              isHidden={isAnyModalVisible}
            />
            <div className="recs-container">
              {lexicon
                .get('sectionTexts')
                .map(
                  (
                    sectionText: string,
                    index: number
                  ): React.Element<'div'> => (
                    <RecSectionWithRedux
                      key={sectionText}
                      lexicon={lexicon}
                      sharedLexicon={sharedLexicon}
                      sectionText={sectionText}
                      sectionName={sectionOrderArr[index]}
                      activeIconName={activeIconName}
                      activeIconSection={activeIconSection}
                      floatingBoxClickHandler={this.floatingBoxClickHandler}
                      iconExplanations={lexicon.get('iconExplanations')}
                      arrowConfig={arrowConfig}
                      ariaHidden={isAnyModalVisible}
                      isFirst={index === 0}
                      locale={locale}
                    />
                  )
                )}
            </div>
            <ConditionalNav
              locale={locale}
              lexicon={lexicon}
              sharedLexicon={sharedLexicon}
              navLinks={navLinks}
              isHidden={isAnyModalVisible}
              noMethodModalClickHandler={this.noMethodModalClickHandler}
              noMethodButtonRef={this.noMethodButtonRef}
            />
            <footer aria-hidden={isAnyModalVisible}>
              {lexicon.get('footer.text')}
              <button
                type="button"
                onClick={this.sourcesModalClickHandler}
                disabled={isAnyModalVisible}
                tabIndex={isAnyModalVisible ? null : '0'}
                ref={this.sourcesButtonRef}
              >
                {lexicon.get('footer.buttonText')}
              </button>
            </footer>
            <ModalWithOverlay
              clickHandler={this.noMethodModalClickHandler}
              isModalOn={isNoMethodModalOn}
              closeBox={sharedLexicon.get('closeBox')}
            >
              <div className="no-method-modal-content">
                <MarkdownBlock
                  str={lexicon.get('noMethodModal.noMethod')}
                  contentRef={this.noMethodContentRef}
                />
                <div className="nav-buttons-container">
                  <button
                    className="nav-button"
                    type="button"
                    onClick={this.noMethodModalClickHandler}
                  >
                    {lexicon.get('noMethodModal.selectMethod')}
                  </button>
                  <div
                    role="presentation"
                    onClick={setModalInvisible}
                    onKeyDown={keyPressHandler}
                  >
                    <NavButton
                      to={`/${locale}/${navLinks[1]}`}
                      str={lexicon.get('noMethodModal.skip')}
                    />
                  </div>
                </div>
              </div>
            </ModalWithOverlay>
            <ModalWithOverlay
              clickHandler={this.sourcesModalClickHandler}
              isModalOn={isSourcesModalOn}
              closeBox={sharedLexicon.get('closeBox')}
              id="sources"
            >
              <div className="sources-modal-content">
                <Headings
                  str={lexicon.get('footer.headings')}
                  externalHeading1Ref={this.modalHeadingRef}
                  id="sources-modal-title"
                />
                <MarkdownBlock str={lexicon.get('footer.paragraphs')} />
              </div>
            </ModalWithOverlay>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  isShowingRecs,
  isAnyModalVisible,
}: State): State => ({
  isShowingRecs,
  isAnyModalVisible,
});
const mapDispatchToProps = {
  ...isAnyModalVisibleActions,
  showInfoClickHandler: (): Object => ({ type: 'SHOW_RECS' }),
};

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
