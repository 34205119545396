// @flow

import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { persistor } from '../../../lib/store';
import userIdActions from '../../../actions/userIdActions';
import Rainbow from '../../shared/rainbow/Rainbow';
import './FormMP.scss';
import type { PageProps } from '../../../lib/types';

let submitInterval;

const FormMP: React.FC = ({ setUserId }: PageProps) => {
  const inputRef = useRef();
  const navigate = useNavigate();

  const [isPurgingDone, setIsPurgingDone] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const submitHandler = () => {
    const { value } = inputRef.current;

    if (value && isPurgingDone) {
      setUserId(value);
      navigate('/en/splash');
    } else if (value) {
      setSubmitting(true);

      submitInterval = setInterval(() => {
        if (isPurgingDone) clearInterval(submitInterval);
        setUserId(value);
        navigate('/en/splash');
      }, 500);
    }
  };

  useEffect(async () => {
    await persistor.purge();
    console.log('purging done');
    setIsPurgingDone(true);
  }, []);

  return (
    <>
      <Rainbow />
      <form className="FormMP">
        <label htmlFor="id">Enter your ID below:</label>
        <input ref={inputRef} id="id" type="text" required />

        <div className="nav-buttons-container">
          <button type="button" className="nav-button" onClick={submitHandler}>
            {submitting ? 'Please Wait' : 'Proceed to MyPath'}
          </button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = userIdActions;

export default connect(null, mapDispatchToProps)(FormMP);
