// @flow

import React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import type { PageProps, State } from '../../../lib/types';
import NavButton from '../../shared/nav/NavButton';
import './FertilityAfterBirth.scss';
import ImgStroller from '../../peripartum/PregnantAgain/mom+stroller-FINAL@2x.png';

let isOptionalPageVisited = false;

export const getIsOptionalPageVisited = (): boolean => isOptionalPageVisited;

const visitOptionalPageRouteUpdate = () => {
  isOptionalPageVisited = true;
};

const FertilityAfterBirth: React.FC = ({ lexicon, isAnyModalVisible }: PageProps) => (
  <>
    <Headings str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
    <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />

    <div className="nav-buttons-container optional-link">
      <img src={ImgStroller} alt="stroller" />
      <div
        className="flex-group"
        role="presentation"
        onClick={visitOptionalPageRouteUpdate}
      >
        <MarkdownBlock str={lexicon.get('paragraph_2')} ariaHidden={isAnyModalVisible} />
        <NavButton
          tabIndex="0"
          to="/en/fertility"
          str={lexicon.get('menstrual_faq')}
        />
      </div>
    </div>
  </>
);

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(FertilityAfterBirth);
