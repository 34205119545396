// @flow

import React from 'react';
import { connect } from 'react-redux';
import currentMethodsActions from '../../../actions/currentMethodsActions';
import {
  Headings,
  MarkdownBlock,
} from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import type {
  PageProps,
  MapStateToProps,
  State,
  Dispatch,
} from '../../../lib/types';
import './CurrentMethods.scss';

type CurrentMethodsProps = PageProps & {
  currentMethods: Array<string>,
  toggleCurrentMethod: Dispatch,
};

const methods = [
  'hormonalIud',
  'copperIud',
  'implant',
  'shot',
  'ring',
  'patch',
  'pill',
  'diaphragm',
  'maleCondom',
  'femaleCondom',
  'cervicalCap',
  'fertilityAwareness',
  'sponge',
  'spermicide',
  'withdrawal',
  'sterilization',
  'notRightNow',
  'emergencyContraception',
  'other',
  'notUsingAnyMethod',
];

const CurrentMethods = ({
  locale,
  currentMethods,
  toggleCurrentMethod,
  navLinks,
  isAnyModalVisible,
  sharedLexicon,
  lexicon,
}: CurrentMethodsProps): React.Element<*> => {
  const isNextButtonHidden = currentMethods.size === 0;

  return (
    <>
      <Headings
        isAutoFocusing
        str={lexicon.get('headings')}
        ariaHidden={isAnyModalVisible}
      />
      <MarkdownBlock
        id="title-block"
        str={lexicon.get('paragraphs')}
        ariaHidden={isAnyModalVisible}
      />
      <div
        className="icon-container"
        role="group"
        aria-labelledby="title-block"
        aria-hidden={isAnyModalVisible}
      >
        {methods.map((name: string): React.Element<'button'> => {
          const isActive = currentMethods.includes(name);
          const extraClassesArr = [name, locale];
          if (isActive) extraClassesArr.push('active');
          const extraClasses = extraClassesArr.join(' ');

          return (
            <div className="icon-button" key={name}>
              <button
                aria-checked={isActive}
                role="checkbox"
                tabIndex={isAnyModalVisible ? null : 0}
                aria-label={sharedLexicon.get(`methodNames.${name}`)}
                onClick={() => {
                  toggleCurrentMethod(name);
                }}
                className={`icon ${extraClasses}`}
                id={`checkbox-${name}-box`}
                type="button"
              />
              <label id={`checkbox-for-${name}`} aria-hidden>
                {sharedLexicon.get(`methodNames.${name}`)}
              </label>
            </div>
          );
        })}
      </div>
      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        backPage={navLinks[0]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  currentMethods,
  isAnyModalVisible,
}: State): Object => ({
  currentMethods,
  isAnyModalVisible,
});
const mapDispatchToProps = currentMethodsActions;

export default connect(mapStateToProps, mapDispatchToProps)(CurrentMethods);
