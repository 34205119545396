// @flow

import React from 'react';

type CalendarBarProps = {
  isInfinity?: boolean,
  isMonthBar?: boolean,
  +activeMethodClassName: string,
  +freq: number,
  +duration: number,
};

type YearBoxProps = {
  item: number | string,
  children: React.ChildrenArray<*>,
};

type YearBoxPeakProps = YearBoxProps & {
  float: string,
};

const InfinityArrow = ({ type }: { type: string }): React.Element<'div'> => (
  <div className={`infinity-arrow-container ${type}`}>
    <div className="infinity-arrow" />
    <div className="infinity-symbol" />
  </div>
);

const YearBoxPeak = ({
  item,
  children,
  float = '',
}: YearBoxPeakProps): React.Element<'div'> => (
  <div className={`year-box peak ${float}`} key={item}>
    <div className="year-text-container">
      <div className="year-text">
        <p>{item}</p>
      </div>
    </div>
    {children}
  </div>
);

const YearBoxRegular = ({
  item,
  children,
}: YearBoxProps): React.Element<'div'> => (
  <div className="year-box" key={item}>
    <p>{item}</p>
    {children}
  </div>
);

const monthArrEn = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
const monthArrEs = [
  'ene',
  'feb',
  'mar',
  'abr',
  'may',
  'jun',
  'jul',
  'ago',
  'sep',
  'oct',
  'nov',
  'dic',
];

const CalendarBar = ({
  /* isEveryOther, */
  activeMethodClassName,
  freq,
  duration,
  isInfinity,
  isMonthBar,
  locale,
}: CalendarBarProps): React.Element<*> => {
  const thisYear = new Date().getFullYear();
  const thisMonth = new Date().getMonth();
  const years = new Array(duration)
    .fill(0)
    .map((v: number, i: number): number => i + thisYear);
  const extraYears = new Array(3)
    .fill(0)
    .map((v: number, i: number): number => i + thisYear + duration);
  let calendarItems = years;
  if (isMonthBar) {
    const monthArr = locale === 'es' ? monthArrEs : monthArrEn;

    calendarItems = monthArr
      .slice(thisMonth)
      .concat(monthArr.slice(0, thisMonth));
  }

  const desktopTickers = isInfinity
    ? [...calendarItems, ...extraYears]
    : calendarItems;
  const mobileTickers =
    activeMethodClassName === 'implant'
      ? [...calendarItems, ...extraYears]
      : calendarItems;
  let mobileCounter = 0;

  return (
    <div className={`calendar ${activeMethodClassName}`} aria-hidden>
      <div className="regular">
        <div className="year-box-container">
          {desktopTickers.map(
            (
              item: number | string,
              j: number,
              arr: Array<*>
            ): React.Element<*> => {
              if (isInfinity && j === arr.length - 1)
                return <InfinityArrow key={item} type="right" />;
              if (j % freq === 0) {
                return (
                  <YearBoxPeak item={item} key={item}>
                    <div className="down-arrow" />
                    <div className="long-pole pole" />
                  </YearBoxPeak>
                );
              }
              return (
                <YearBoxRegular item={item} key={item}>
                  <div className="long-pole pole" />
                </YearBoxRegular>
              );
            }
          )}
        </div>
        <div className="main-bar" />
      </div>
      <div className="mobile">
        <div className="main-bar" />
        {isInfinity && <InfinityArrow type="down" />}
        <div className="pole-container">
          {mobileTickers.map((item: number | string): React.Element<'div'> => (
            <div className="pole" key={item} />
          ))}
        </div>
        <div className="year-box-container">
          {mobileTickers.map((item: number | string, j: number): React.Node => {
            if (j % freq === 0) {
              mobileCounter += 1;
              return (
                <YearBoxPeak
                  key={item}
                  item={item}
                  float={mobileCounter % 2 === 0 ? 'right' : 'left'}
                >
                  <div className="left-right-arrow" />
                </YearBoxPeak>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

CalendarBar.defaultProps = {
  isInfinity: false,
  isMonthBar: false,
};

export default CalendarBar;
