// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Lexicon } from '@nitidbit/lexicon';
import CategoryBar from './CategoryBar';
import SilhouetteInterface from '../../shared/silhouetteInterface/SilhouetteInterface';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import Note from '../../shared/note/Note';
import markdown from '../../../lib/utils';
import type { SideEffectsProps } from '../../../lib/types';

import './SideEffects.scss';
import '../../shared/HowToPage.scss';

type SideEffectState = {
  categoryNum: number | null,
};

type FakeNavButtonProps = {
  clickHandler: number => void,
  children: React.Node,
  sharedLexicon: Lexicon,
};

type SideEffectsNavContainerProps = SideEffectState & {
  locale: string,
  sharedLexicon: Lexicon,
  navLinks: Array<string>,
  fakeClickHandler: number => void,
};

const bcEffectObjEnglish = [
  {
    iconNames: [
      'decreasedAcne',
      'cancerPrevention',
      'decreasedCramping',
      'noPeriod',
      'decreasedPeriod'
    ]
  },
  {
    iconNames: [
      'depression',
      'decreasedInterestInSex',
      'cramping',
      'weightGain',
      'noPeriod',
      'spotting',
      'heavierPeriod'
    ]
  },
  {
    iconNames: [
      'depression',
      'nausea',
      'breastTenderness',
      'weightGain'
    ]
  }
];

const sideEffectCategories = ['benefits', 'side-effects', 'other-considerations'];

const SideEffectsConfig = [
  {
    decreasedAcne: ['up', 'bottom', 'left'],
    cancerPrevention: ['up', 'top', 'left'],
    decreasedCramping: ['down', 'bottom', 'left'],
    noPeriod: ['up', 'top', 'right'],
    decreasedPeriod: ['down', 'bottom', 'right'],
  },
  {
    depression: ['up', 'bottom', 'left'],
    decreasedInterestInSex: ['up', 'bottom', 'left'],
    cramping: ['down', 'bottom', 'left'],
    weightGain: ['down', 'bottom', 'left'],
    noPeriod: ['up', 'top', 'right'],
    spotting: ['down', 'top', 'right'],
    heavierPeriod: ['down', 'bottom', 'right'],
  },
  {
    depression: ['up', 'bottom', 'left'],
    nausea: ['down', 'bottom', 'left'],
    breastTenderness: ['up', 'bottom', 'right'],
    weightGain: ['down', 'bottom', 'right'],
  },
];

export const FakeNavButtonContainer = ({ clickHandler, children = null, sharedLexicon }: FakeNavButtonProps): React.Element<'div'> => (
  <div className="nav-buttons-container fake">
    <button onClick={clickHandler} type="button" className="nav-button">
      {sharedLexicon.get('navigation.backText')}
    </button>

    {children}
  </div>
);

const SideEffectsNavContainer = ({
  locale,
  fakeClickHandler,
  categoryNum,
  navLinks,
  sharedLexicon
}: SideEffectsNavContainerProps): React.Element<'div'> => {
  const mobileNavigation = (
    <FakeNavButtonContainer
      clickHandler={fakeClickHandler}
      sharedLexicon={sharedLexicon}
    >
      <Note sharedLexicon={sharedLexicon} />
    </FakeNavButtonContainer>
  );

  const navigation = (
    <NavContainer
      nextPage={navLinks[1]}
      backPage={navLinks[0]}
      locale={locale}
      sharedLexicon={sharedLexicon}
    />
  );

  return (
    <div className="side-effects-navigation-container">
      <div className="regular">{navigation}</div>
      <div className="mobile">
        {categoryNum !== null ? mobileNavigation : navigation}
      </div>
    </div>
  );
};

class SideEffects extends React.Component<SideEffectsProps, SideEffectState> {
  _mobileHeading: HTMLHeadingElement;

  constructor(props: SideEffectsProps) {
    super(props);
    this.state = {
      categoryNum: null,
      prevCategoryNum: null,
    };
  }

  // componentDidMount() { this.props.setModalInvisible(); }

  mobileHeadingRef = (c: ?HTMLHeadingElement) => { this._mobileHeading = c; }

  categoryButtonHandler = (categoryNum: number | null) => {
    this.setState({ categoryNum }, () => {
      if (categoryNum !== null) this._mobileHeading.focus();
    });
  }

  render() {
    const { categoryNum, prevCategoryNum } = this.state;
    const {
      content,
      navLinks,
      locale,
      isAnyModalVisible,
      sharedLexicon,
      lexicon,
    } = this.props;

    const categoryNames = [0, 1, 2].map((i: number): string => lexicon.get(`bcEffectObj.${i}.category`));
    const introText = categoryNum === null ? '' : lexicon.get(`bcEffectObj.${categoryNum}.introText`);
    const mobileCategoryPageHeading = categoryNum === null ? '' : `# ${categoryNames[categoryNum]}`;
    const overlayClassName = categoryNum === null ? '' : 'no-display';
    const categoryPageClassName = categoryNum === null ? 'no-display' : 'mobile-category-page-top';

    const fakeClickHandler = () => {
      this.setState({ prevCategoryNum: categoryNum });
      this.categoryButtonHandler(null);
    };

    return (
      <>
        <div className="flexbox-group regular">
          <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
          <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
          <div className="category-bar-container">
            <CategoryBar
              categoryNames={categoryNames}
              clickHandler={this.categoryButtonHandler}
              categoryNum={categoryNum}
            />
            {
              categoryNum !== null && categoryNames.map((str: string, i: number): React.Element<'div'> => (
                <div
                  key={lexicon.get(`bcEffectObj.${i}.introText`)}
                  role="tabpanel"
                  id="category-intro-text"
                  hidden={i === categoryNum ? null : 'hidden'}
                  tabIndex={isAnyModalVisible ? null : '0'}
                  dangerouslySetInnerHTML={{ __html: markdown(lexicon.get(`bcEffectObj.${i}.introText`)) }}
                />
              ))
            }
          </div>
        </div>

        <div className={`mobile mobile-flex-group mobile-intro-overlay ${overlayClassName}`}>
          <Headings str={lexicon.get('headings')} isAutoFocusing />
          <MarkdownBlock str={lexicon.get('paragraphs')} />
          {
            categoryNum === null && (
              <CategoryBar
                categoryNames={categoryNames}
                clickHandler={this.categoryButtonHandler}
                categoryNum={categoryNum}
                prevCategoryNum={prevCategoryNum}
                mobileButtonLabel={sharedLexicon.get('newModal')}
                isMobile
              />
            )
          }
        </div>
        <div className={`mobile ${categoryPageClassName}`}>
          <Headings str={mobileCategoryPageHeading} externalHeading1Ref={this.mobileHeadingRef} />
          <MarkdownBlock str={introText} />
        </div>
        {
          categoryNum === 0 && (
            <SilhouetteInterface
              config={SideEffectsConfig[0]}
              iconNamesEnglish={bcEffectObjEnglish[0].iconNames}
              categoryName={sideEffectCategories[0]}
              iconExplanations={lexicon.get('bcEffectObj.0.explanations')}
              toggleOff={categoryNum !== 0}
              iconNames={bcEffectObjEnglish[0].iconNames.map((str: string): string => sharedLexicon.get(`sideEffectNames.${str}`))}
              sharedLexicon={sharedLexicon}
            />
          )
        }
        {
          categoryNum === 1 && (
            <SilhouetteInterface
              config={SideEffectsConfig[1]}
              iconNamesEnglish={bcEffectObjEnglish[1].iconNames}
              categoryName={sideEffectCategories[1]}
              iconExplanations={lexicon.get('bcEffectObj.1.explanations')}
              toggleOff={categoryNum !== 1}
              iconNames={bcEffectObjEnglish[1].iconNames.map((str: string): string => sharedLexicon.get(`sideEffectNames.${str}`))}
              sharedLexicon={sharedLexicon}
            />
          )
        }
        {
          categoryNum === 2 && (
            <SilhouetteInterface
              config={SideEffectsConfig[2]}
              iconNamesEnglish={bcEffectObjEnglish[2].iconNames}
              categoryName={sideEffectCategories[2]}
              iconExplanations={lexicon.get('bcEffectObj.2.explanations')}
              toggleOff={categoryNum !== 2}
              iconNames={bcEffectObjEnglish[2].iconNames.map((str: string): string => sharedLexicon.get(`sideEffectNames.${str}`))}
              sharedLexicon={sharedLexicon}
            />
          )
        }

        <SideEffectsNavContainer
          locale={locale}
          content={content}
          fakeClickHandler={fakeClickHandler}
          categoryNum={categoryNum}
          navLinks={navLinks}
          sharedLexicon={sharedLexicon}
        />
      </>
    );
  }
}

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(SideEffects);
