// @flow

import React, { useEffect, useRef } from 'react';
import IconDownArrow from './double-arrow-bottom-icon.svg';

import './ModalWithOverlay.scss';

type ModalProps = {
  clickHandler: (*) => void,
  isModalOn: boolean,
  showCloseButton?: boolean,
  id?: string,
  extraClass: string,
  children: React.Node,
  closeBox: string,
  modalLabel: string | null,
};

type CloseButtonProps = {
  clickHandler: (*) => void,
  isButtonInTabDisabled: boolean,
  str: string,
};

export const CloseButton = ({
  clickHandler,
  isButtonInTabDisabled = false,
  str,
}: CloseButtonProps): React.Element<'button'> => (
  <button
    className="close-x"
    tabIndex="0"
    onClick={clickHandler}
    disabled={isButtonInTabDisabled}
    type="button"
  >
    {str}
  </button>
);

const stopBubbling = (e: SyntheticEvent<HTMLDivElement>) => {
  e.stopPropagation();
};

const ModalWithOverlay = ({
  id = null,
  clickHandler,
  isModalOn,
  children,
  showCloseButton,
  closeBox,
  extraClass = '',
  modalLabel = null,
}: ModalProps): React.Element<'div'> => {
  const modalRef = useRef();
  const imgRef = useRef();

  const scrollHandler = () => {
    if (isModalOn) {
      const myDiv = modalRef.current;
      if (!myDiv) return;

      if (myDiv.scrollHeight - (myDiv.offsetHeight + myDiv.scrollTop) < 2) {
        imgRef.current.classList.remove('visible');
      } else {
        imgRef.current.classList.add('visible');
      }
    }
  };

  useEffect(() => {
    scrollHandler();
  }, [isModalOn]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div
      id={id}
      role="presentation"
      className={`overlay ${isModalOn ? '' : 'hidden'}`}
      onClick={(e: SyntheticEvent<HTMLDivElement>) => {
        e.preventDefault();
        clickHandler();
      }}
    >
      <div
        className={`modal ${extraClass}`}
        onClick={stopBubbling}
        onScroll={scrollHandler}
        role="dialog"
        aria-label={modalLabel}
        aria-labelledby={id ? `${id}-modal-title` : null}
        ref={modalRef}
      >
        {children}

        {showCloseButton && (
          <CloseButton clickHandler={clickHandler} str={closeBox} />
        )}

        <img src={IconDownArrow} alt="" className="scroll-down" ref={imgRef} />
      </div>
    </div>
    /* eslint-enable jsx-a11y/click-events-have-key-events */
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
  );
};

ModalWithOverlay.defaultProps = {
  id: undefined,
  showCloseButton: true,
};

export default ModalWithOverlay;
