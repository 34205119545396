// @flow

import React from "react";
import NavButton from "../../shared/nav/NavButton";
import InControl from "./InControlLogo.png";
import UCSFtoolsFooter from "../../shared/footers/UCSFtoolsFooter";
import { isInControl } from "../../../lib/appMode";
import "./Splash.scss";
import type { PageProps } from "../../../lib/types";

const Splash = ({
  navLinks,
  sharedLexicon,
  locale,
}: PageProps): React.ChildrenArray<*> => (
  <>
    <div className="spacer-1" />
    <div className="flexbox-group">
      <div className="hello-beautiful" />
      <div className="locale-buttons-container">
        {isInControl && (
          <img className="in-control" src={InControl} alt="In Control" />
        )}
        <div>
          <NavButton
            str={sharedLexicon.get("EnglishSplash")}
            to={navLinks[0]}
            tabIndex="0"
          />
          <NavButton
            str={sharedLexicon.get("SpanishSplash")}
            to={navLinks[1]}
            tabIndex="0"
          />
        </div>
      </div>
    </div>
    <div className="spacer-2" />
    <UCSFtoolsFooter id="splash-footer" key="footer" />
  </>
);

export default Splash;
