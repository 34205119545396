import HelloBeautiful from "./components/mybc/splashMBC/Splash";
import BigDeal from "./components/mybc/introMBC/Intro";
import Effectiveness from "./components/mybc/effectiveness/Effectiveness";
import MainMBC from "./components/mybc/MainMBC/Main";
import HowToWomen from "./components/mybc/howToWomen/HowToWomen";
import HowToMen from "./components/mybc/howToMen/HowToMen";
import EmergencyContraception from "./components/mybc/emergencyContraception/EmergencyContraception";
import FertilityAwareness from "./components/mybc/fertilityAwareness/FertilityAwareness";
import HowOften from "./components/mybc/howOften/HowOften";
import SideEffectPreface from "./components/mybc/sideEffectPreface/SideEffectPreface";
import SideEffects from "./components/mybc/sideEffects/SideEffects";
import SideEffectPerMethod from "./components/mybc/sideEffectsPerMethod/SideEffectsPerMethod";
import FertilityFuture from "./components/mybc/fertilityFuture/FertilityFuture";
import SplashMP from "./components/mypath/SplashMP/SplashMP";
import IntroMP from "./components/mypath/IntroMP/IntroMP";
import MainMP from "./components/mypath/MainMP/MainMP";
import SurveyMP from "./components/mypath/SurveyMP/SurveyMP";
import Survey1MBC from "./components/mybc/surveyMBC/Survey1MBC";
import Survey2MBC from "./components/mybc/surveyMBC/Survey2MBC";
import Survey3MBC from "./components/mybc/surveyMBC/Survey3MBC";
import Survey4MBC from "./components/mybc/surveyMBC/Survey4MBC";
import Survey5MBC from "./components/mybc/surveyMBC/Survey5MBC";
import CurrentMethods from "./components/mypath/currentMethods/CurrentMethods";
import FertilityMenu from "./components/mypath/fertility/FertilityMenu";
import HealthPregnancyMenu from "./components/mypath/health/HealthPregnancyMenu";
import HealthPregnancyTopics from "./components/mypath/health/HealthPregnancyTopics";
import CurrentMethodsSurvey from "./components/mypath/currentMethodsSurvey/CurrentMethodsSurvey";
import Recommendations from "./components/mybc/recommendations/Recommendations";
import CompareMethods from "./components/mybc/compareMethods/CompareMethods";
import Summary from "./components/mybc/summary/Summary";
import SecretDeveloperPage from "./components/shared/SecretDeveloperPage";
import SexAfterBaby from "./components/peripartum/SexAfterBaby/SexAfterBaby";
import TakeAMoment from "./components/peripartum/IntroPeri/IntroPeripartum";
import PregnantAgainFirst from "./components/peripartum/PregnantAgain/PregnantAgainFirst";
import PregnantAgainSecond from "./components/peripartum/PregnantAgain/PregnantAgainSecond";
import Breastfeeding from "./components/peripartum/Breastfeeding/Breastfeeding";
import ImportantInfo from "./components/peripartum/ImportantInformation/ImportantInformation";
import Considerations from "./components/peripartum/Considerations/Considerations";
import GotQuestions from "./components/mybc/gotQuestions/GotQuestions";
import StudyMP from "./components/mypath/StudyMP/StudyMP";
import FertilitySurveyMP from "./components/mypath_peripartum/FertilitySurvey/FertilitySurvey";
import FertilityAfterBirth from "./components/mypath_peripartum/FertilityAfterBirth/FertilityAfterBirth";
import SexAfterBayMP from "./components/mypath_peripartum/SexAfterBayMP";
import BarrierSurvey from "./components/in_control/BarrierSurvey";

const sharedRoutes = {
  effectiveness: {
    Component: Effectiveness,
    additionalClasses: "icon_array_page",
    back: "main_nav",
    next: "main_nav",
  },
  side_effect_preface: {
    Component: SideEffectPreface,
    back: "main_nav",
    next: "side_effects",
  },
  side_effects_per_method: {
    Component: SideEffectPerMethod,
    back: "side_effects",
    next: "main_nav",
  },
  how_to_women: {
    Component: HowToWomen,
    additionalClasses: "how_to_page",
    next: "how_to_men",
    back: "main_nav",
  },
  how_to_men: {
    Component: HowToMen,
    additionalClasses: "how_to_page",
    back: "how_to_women",
    next: "emergency_contraception",
  },
  how_often: {
    Component: HowOften,
    additionalClasses: "icon_array_page",
    back: "main_nav",
    next: "main_nav",
  },
  fertility_future: {
    Component: FertilityFuture,
    back: "main_nav",
    next: "main_nav",
    hideP4YButton: true,
  },
  survey1_mbc: {
    Component: Survey1MBC,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["main_nav", "survey2_mbc"],
  },
  survey2_mbc: {
    Component: Survey2MBC,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["survey1_mbc", "survey3_mbc"],
  },
  survey3_mbc: {
    Component: Survey3MBC,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["survey2_mbc", "survey4_mbc"],
  },
  survey4_mbc: {
    Component: Survey4MBC,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["survey3_mbc", "survey5_mbc"],
  },
  survey5_mbc: {
    Component: Survey5MBC,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["survey4_mbc", "recommendations"],
  },
  compare_methods: {
    Component: CompareMethods,
    hideNavigation: true,
    hideP4YButton: true,
    navLinks: ["recommendations"],
  },
  summary: {
    Component: Summary,
    hideNavigation: true,
  },
  secret_developer_page: {
    Component: SecretDeveloperPage,
    hideNavigation: true,
    hideP4YButton: true,
  },
  "secret-developer-page": {
    Component: SecretDeveloperPage,
    hideNavigation: true,
    hideP4YButton: true,
  },
};

const myPathRoutes = {
  ...sharedRoutes,
  splash: {
    Component: SplashMP,
    hideNavigation: true,
    hideP4YButton: true,
  },
  study_mp: {
    Component: StudyMP,
    hideNavigation: true,
    hideP4YButton: true,
  },
  intro_mp: {
    Component: IntroMP,
    hideNavigation: true,
    hideP4YButton: true,
  },
  main_nav_mp: {
    Component: MainMP,
    navLinks: [
      "survey_mp",
      "fertility",
      "health_before_pregnancy",
      "current_methods",
      "summary",
    ],
  },
  survey_mp: {
    Component: SurveyMP,
    additionalClasses: "survey_page",
    hideNavigation: true,
    navLinks: ["main_nav_mp", "survey2_mp"],
  },
  current_methods: {
    Component: CurrentMethods,
    hideNavigation: true,
    back: "main_nav_mp",
    next: "survey2_mp",
    navLinks: ["main_nav_mp", "current_methods_survey"],
  },
  fertility: {
    Component: FertilityMenu,
    back: "main_nav_mp",
    next: "main_nav_mp",
  },
  health_before_pregnancy: {
    Component: HealthPregnancyMenu,
    back: "main_nav_mp",
    next: "health_before_pregnancy_topics",
  },
  health_before_pregnancy_topics: {
    Component: HealthPregnancyTopics,
    back: "health_before_pregnancy",
    next: "main_nav_mp",
  },
  current_methods_survey: {
    Component: CurrentMethodsSurvey,
    additionalClasses: "survey_page",
    hideNavigation: true,
    navLinks: ["current_methods", "main_nav"],
  },
  main_nav: {
    Component: MainMBC,
    navLinks: [
      "effectiveness",
      "how_to_women",
      "how_often",
      "side_effects",
      "fertility_future",
      "survey1_mbc",
    ],
  },
  recommendations: {
    Component: Recommendations,
    hideNavigation: true,
    hideP4YButton: true,
    navLinks: ["compare_methods", "main_nav_mp"],
  },
  side_effects: {
    Component: SideEffects,
    additionalClasses: "how_to_page",
    hideNavigation: true,
    navLinks: ["main_nav", "side_effects_per_method"],
  },
  emergency_contraception: {
    Component: EmergencyContraception,
    back: "how_to_men",
    next: "fertility_awareness",
  },
  fertility_awareness: {
    Component: FertilityAwareness,
    back: "emergency_contraception",
    next: "main_nav",
  },
};

const myPathPeripartumRoutes = {
  ...myPathRoutes,
  main_nav_mp: {
    Component: MainMP,
    navLinks: [
      "sex_after_baby",
      "fertility_survey_mp",
      "health_before_pregnancy",
      "main_nav",
      "summary",
    ],
  },
  sex_after_baby: {
    Component: SexAfterBayMP,
    next: "main_nav_mp",
    back: "main_nav_mp",
  },
  fertility_survey_mp: {
    Component: FertilitySurveyMP,
    hideNavigation: true,
    additionalClasses: "survey_page",
    navLinks: ["main_nav_mp", "fertility_after_birth"],
  },
  fertility_after_birth: {
    Component: FertilityAfterBirth,
    back: "fertility_survey_mp",
    next: "pregnant_again_first",
  },
  fertility: {
    Component: FertilityMenu,
    back: "fertility_after_birth",
    next: "pregnant_again_first",
  },
  pregnant_again_first: {
    Component: PregnantAgainFirst,
    hideNavigation: true,
    navLinks: [null, "main_nav_mp"],
  },
  main_nav: {
    Component: MainMBC,
    navLinks: [
      "important_information",
      "effectiveness",
      "how_to_women",
      "how_often",
      "side_effects",
      "fertility_future",
      "considerations",
    ],
    back: "main_nav_mp",
  },
  important_information: {
    Component: ImportantInfo,
    next: "breastfeeding",
    back: "main_nav",
  },
  breastfeeding: {
    Component: Breastfeeding,
    back: "important_information",
    next: "main_nav",
  },
  considerations: {
    Component: Considerations,
    back: "main_nav",
    next: "survey1_mbc",
  },
};

const myBCRoutes = {
  ...sharedRoutes,
  splash: {
    Component: HelloBeautiful,
    hideNavigation: true,
    navLinks: ["/en/intro", "/es/intro"],
  },
  intro: {
    Component: BigDeal,
    hideNavigation: true,
  },
  main_nav: {
    Component: MainMBC,
    navLinks: [
      "effectiveness",
      "how_to_women",
      "how_often",
      "side_effect_preface",
      "fertility_future",
      "survey1_mbc",
    ],
  },
  recommendations: {
    Component: Recommendations,
    hideNavigation: true,
    navLinks: ["compare_methods", "got_questions"],
  },
  side_effects: {
    Component: SideEffects,
    additionalClasses: "how_to_page",
    hideNavigation: true,
    navLinks: ["side_effect_preface", "side_effects_per_method"],
  },
  got_questions: {
    Component: GotQuestions,
    hideNavigation: true,
    navLinks: ["", "summary"],
  },
  emergency_contraception: {
    Component: EmergencyContraception,
    back: "how_to_men",
    next: "main_nav",
  },
};

const inControlRoutes = {
  ...myBCRoutes,
  main_nav: {
    Component: MainMBC,
    isShowingInControlButton: true,
    navLinks: [
      "effectiveness",
      "how_to_women",
      "how_often",
      "side_effect_preface",
      "fertility_future",
      "survey1_mbc",
    ],
  },
  recommendations: {
    Component: Recommendations,
    hideNavigation: true,
    navLinks: ["compare_methods", "barrier_survey"],
  },
  barrier_survey: {
    Component: BarrierSurvey,
    back: "recommendations",
    next: "summary",
  },
};

const peripartumRoutes = {
  ...sharedRoutes,
  splash: {
    Component: HelloBeautiful,
    hideNavigation: true,
    navLinks: ["/en/intro", "/es/intro"],
  },
  intro: {
    Component: TakeAMoment,
    hideNavigation: true,
  },
  main_nav: {
    Component: MainMBC,
    navLinks: [
      "sex_after_baby",
      "effectiveness",
      "how_to_women",
      "how_often",
      "side_effect_preface",
      "fertility_future",
      "considerations",
    ],
  },
  recommendations: {
    Component: Recommendations,
    hideNavigation: true,
    navLinks: ["compare_methods", "got_questions"],
  },
  sex_after_baby: {
    Component: SexAfterBaby,
    next: "pregnant_again_first",
    back: "main_nav",
  },
  pregnant_again_first: {
    Component: PregnantAgainFirst,
    next: "pregnant_again_second",
    back: "sex_after_baby",
  },
  pregnant_again_second: {
    Component: PregnantAgainSecond,
    next: "breastfeeding",
    back: "pregnant_again_first",
  },
  breastfeeding: {
    Component: Breastfeeding,
    next: "important_information",
    back: "pregnant_again_second",
  },
  important_information: {
    Component: ImportantInfo,
    next: "main_nav",
    back: "breastfeeding",
  },
  considerations: {
    Component: Considerations,
    back: "main_nav",
    next: "survey1_mbc",
  },
  side_effects: {
    Component: SideEffects,
    additionalClasses: "how_to_page",
    hideNavigation: true,
    navLinks: ["side_effect_preface", "side_effects_per_method"],
  },
  got_questions: {
    Component: GotQuestions,
    hideNavigation: true,
    navLinks: ["", "summary"],
  },
  emergency_contraception: {
    Component: EmergencyContraception,
    back: "how_to_men",
    next: "main_nav",
  },
};

export {
  myPathRoutes,
  myBCRoutes,
  peripartumRoutes,
  myPathPeripartumRoutes,
  inControlRoutes,
};
