// @flow

import React from 'react';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps } from '../../../lib/types';
import './PregnantAgain.scss';

const PregnantAgainSecond = ({ lexicon }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={lexicon.get('headings')} />
    <div className="flex-group">
      <div className="image-space regular" />
      <MarkdownBlock str={lexicon.get('paragraphs')} />
      <div className="spacer" />
    </div>
  </>
);

export default PregnantAgainSecond;
