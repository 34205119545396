// @flow

import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Lexicon } from "@nitidbit/lexicon";
import { isMyPath4you, isInControl, isMyPathPeripartum } from "../lib/appMode";

import P4YButton from "./mypath/path4you/P4YButton";
import BackToInControlButton from "./in_control/BackToInControlButton/BackToInControlButton";
import NavContainer from "./shared/nav/NavContainer";

type NavigationManagerProps = {
  fullLexicon: Lexicon,
  routes: {
    [pageKey]: {
      component: React.FC,
      hideNavigation: boolean,
      hideNotes: boolean,
      isShowingInControlButton: boolean,
      hideP4YButton: boolean,
      navLinks: [string],
      back: string,
      next: string,
    },
  },
};

const NavigationManager: React.FC = ({
  fullLexicon,
  routes,
}: NavigationManagerProps) => {
  const { pathname } = useLocation();

  const pageKey = pathname.slice(pathname.lastIndexOf("/") + 1) || "splash";
  const currentPage = routes[pageKey];
  if (!currentPage) return null;

  /* calculate copies */
  const locale = pathname.startsWith("/es/") ? "es" : "en";
  const sharedLexicon = fullLexicon.locale(locale).subset("shared");
  const myPathPeripartumMainBackNav =
    isMyPathPeripartum && pageKey === "main_nav"
      ? sharedLexicon.get("navigation.backToMainMP")
      : "";

  /* calculate conditional rendering */
  const isShowingNavContainer = !currentPage.hideNavigation;
  const isShowingInControlButton =
    isInControl && currentPage.isShowingInControlButton;
  const isShowingP4YButton = isMyPath4you && !currentPage.hideP4YButton;

  if (
    !isShowingNavContainer &&
    !isShowingInControlButton &&
    !isShowingP4YButton
  )
    return null;

  return (
    <div className={`NavigationManager ${pageKey}`}>
      {isShowingNavContainer && (
        <NavContainer
          locale={locale}
          nextPage={currentPage.next}
          backPage={currentPage.back}
          hideNotes={currentPage.hideNotes}
          sharedLexicon={sharedLexicon}
          backText={myPathPeripartumMainBackNav}
        />
      )}
      {isMyPath4you && isShowingP4YButton && <P4YButton locale={locale} />}
      {isShowingInControlButton && (
        <BackToInControlButton text={sharedLexicon.get("backToInControl")} />
      )}
    </div>
  );
};

export default NavigationManager;
