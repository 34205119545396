// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Lexicon } from '@nitidbit/lexicon';
import { MarkdownBlock } from '../textComponents/TextComponents';
import { isMyBC } from '../../../lib/appMode';
import Modal from '../modal/ModalWithOverlay';
import Instruction from './Instruction';
import noteActions from '../../../actions/noteActions';
import navVisibilityActions from '../../../actions/isAnyModalVisibleActions';
import './Note.scss';

type Props = {
  notes: ?string,
  onSave: ?(string) => void,
  onShowNotes: ?() => void,
  onHideInstructions: ?() => void,
  showNotesInstructions: boolean,
  sharedLexicon: Lexicon,
  setModalInvisible: () => {},
  setModalVisible: () => {},
  isAnyModalVisible: boolean,
};

type State = {
  modalVisible: boolean,
  notes: string,
};

class Note extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalVisible: false,
      notes: this.props.notes || '',
    };
  }

  NoteModalHeadingRef = (c: ?HTMLHeadingElement) => { this._noteModalHeading = c; }
  NoteButtonRef = (c: ?HTMLButtonElement) => { this._noteButton = c; }

  hideModal = () => {
    const { setModalInvisible } = this.props;
    setModalInvisible();
    this.setState({ modalVisible: false }, () => { this._noteButton.focus(); });
  }

  showModal = () => {
    const { onShowNotes, setModalVisible } = this.props;

    if (onShowNotes) { onShowNotes(); }
    setModalVisible();
    this.setState({ modalVisible: true }, () => { this._noteModalHeading.focus(); });
  }

  handleCancel = () => {
    const { notes } = this.props;

    this.setState({ notes: notes || '' });
    this.hideModal();
  }

  handleSave = () => {
    const { onSave } = this.props;
    const { notes } = this.state;

    if (onSave) { onSave(notes); }
    this.hideModal();
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ notes: event.currentTarget.value });
  }

  render() {
    const { notes, modalVisible } = this.state;
    const {
      showNotesInstructions,
      onHideInstructions,
      sharedLexicon,
      isAnyModalVisible,
    } = this.props;

    return ([
      <Instruction
        visible={showNotesInstructions}
        onCloseClick={onHideInstructions}
        header={sharedLexicon.get('notes.instructionsHeader')}
        body={sharedLexicon.get('notes.instructionsBody')}
        key="instructions"
      />,
      <button
        disabled={isAnyModalVisible}
        aria-hidden={isAnyModalVisible}
        onClick={this.showModal}
        key="button"
        type="button"
        id="view-notes-button"
        ref={this.NoteButtonRef}
        tabIndex="0"
      >
        {`${sharedLexicon.get('notes.buttonText')} ${sharedLexicon.get('notes.instructionsHeader')} ${sharedLexicon.get('notes.instructionsBody')}`}
      </button>,
      <Modal
        id="notes-modal"
        isModalOn={modalVisible}
        clickHandler={this.hideModal}
        showCloseButton={false}
        key="modal"
      >
        <h1 className="focus-start" tabIndex="-1" ref={this.NoteModalHeadingRef}>{sharedLexicon.get('notes.headerText')}</h1>
        <div className="scroll-container">
          <p className="short">{sharedLexicon.get('notes.shortDescription')}</p>
          <p className="long">{sharedLexicon.get('notes.longDescriptionFirst')}</p>
          { isMyBC && <p className="long">{sharedLexicon.get('notes.longDescriptionSecond')}</p>}

          <div className="textarea-container">
            {notes === '' && (
              <div className="placeholder" aria-hidden>
                <MarkdownBlock str={sharedLexicon.get('notes.placeholder')} />
              </div>
            )}
            <textarea
              aria-label={sharedLexicon.get('notes.headerText')}
              id="textarea"
              value={notes}
              onChange={this.handleChange}
              placeholder={sharedLexicon.get('notes.placeholder')}
            />
          </div>
        </div>

        <button className="cancel" onClick={this.handleCancel} key="cancel" type="button">
          {sharedLexicon.get('notes.cancel')}
        </button>
        <button className="save" onClick={this.handleSave} key="save" type="button">
          {sharedLexicon.get('notes.save')}
        </button>
      </Modal>,
    ]);
  }
}

const mapStateToProps = (state: Object): Object => ({
  notes: state.notesReducer.notes,
  showNotesInstructions: state.notesReducer.showNotesInstructions,
  isAnyModalVisible: state.isAnyModalVisible,
});

const mapDispatchToProps = { ...noteActions, ...navVisibilityActions };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Note);
