// @flow

import React from 'react';
import { ReactComponent as Bear } from './animals/bear.svg';
import { ReactComponent as Beaver } from './animals/beaver.svg';
import { ReactComponent as Bee } from './animals/bee.svg';
import { ReactComponent as Butterfly } from './animals/butterfly.svg';
import { ReactComponent as Cat } from './animals/cat.svg';
import { ReactComponent as Caterpillar } from './animals/caterpillar.svg';
import { ReactComponent as Cow } from './animals/cow.svg';
import { ReactComponent as Dog } from './animals/dog.svg';
import { ReactComponent as Duck } from './animals/duck.svg';
import { ReactComponent as Elephant } from './animals/elephant.svg';
import { ReactComponent as Fish } from './animals/fish.svg';
import { ReactComponent as Fox } from './animals/fox.svg';
import { ReactComponent as Frog } from './animals/frog.svg';
import { ReactComponent as Giraffe } from './animals/giraffe.svg';
import { ReactComponent as Horse } from './animals/horse.svg';
import { ReactComponent as Lion } from './animals/lion.svg';
import { ReactComponent as Monkey } from './animals/monkey.svg';
import { ReactComponent as Mouse } from './animals/mouse.svg';
import { ReactComponent as Owl } from './animals/owl.svg';
import { ReactComponent as Pig } from './animals/pig.svg';
import { ReactComponent as Rabbit } from './animals/rabbit.svg';
import { ReactComponent as Raccoon } from './animals/raccoon.svg';
import { ReactComponent as Rhino } from './animals/rhino.svg';
import { ReactComponent as Seal } from './animals/seal.svg';
import { ReactComponent as Snail } from './animals/snail.svg';
import { ReactComponent as Snake } from './animals/snake.svg';
import { ReactComponent as Turtle } from './animals/turtle.svg';
import { ReactComponent as Unicorn } from './animals/unicorn.svg';
import { ReactComponent as Whale } from './animals/whale.svg';

type animalProps = { name: string };

const Animal = ({ name }: animalProps): React.Element<*> => {
  switch (name) {
    case 'bear':
      return <Bear />;
    case 'beaver':
      return <Beaver />;
    case 'bee':
      return <Bee />;
    case 'butterfly':
      return <Butterfly />;
    case 'cat':
      return <Cat />;
    case 'caterpillar':
      return <Caterpillar />;
    case 'cow':
      return <Cow />;
    case 'dog':
      return <Dog />;
    case 'duck':
      return <Duck />;
    case 'elephant':
      return <Elephant />;
    case 'fish':
      return <Fish />;
    case 'fox':
      return <Fox />;
    case 'frog':
      return <Frog />;
    case 'giraffe':
      return <Giraffe />;
    case 'horse':
      return <Horse />;
    case 'lion':
      return <Lion />;
    case 'monkey':
      return <Monkey />;
    case 'mouse':
      return <Mouse />;
    case 'owl':
      return <Owl />;
    case 'pig':
      return <Pig />;
    case 'rabbit':
      return <Rabbit />;
    case 'raccoon':
      return <Raccoon />;
    case 'rhino':
      return <Rhino />;
    case 'seal':
      return <Seal />;
    case 'snail':
      return <Snail />;
    case 'snake':
      return <Snake />;
    case 'turtle':
      return <Turtle />;
    case 'unicorn':
      return <Unicorn />;
    case 'whale':
      return <Whale />;
    default:
      return null;
  }
};

export default Animal;
