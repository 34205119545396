// @flow
import type { Action, SurveyStateMP } from '../lib/types';
import { isMyPathPeripartum } from '../lib/appMode';

type SurveyAction = Action & { index: number };

export const numOfQuestionToUnlockMainNavs = isMyPathPeripartum ? -1 : 7;

const initialState = new Array(numOfQuestionToUnlockMainNavs + 1);

const prefilledInitialState = new Array(8).fill(1);

const surveyReducerMP = (state: SurveyStateMP = initialState, action: SurveyAction): SurveyStateMP => {
  const newAnswers = state.slice();

  switch (action.type) {
    case 'ANSWER_SURVEY_SINGLE_MP':
      newAnswers[action.index] = action.value;

      return newAnswers;
    case 'SECRET_PREFILL_SURVEY':
      return prefilledInitialState;
    default:
      return state;
  }
};

export default surveyReducerMP;
