// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import navActions from '../../../actions/navActions';
import type { Dispatch } from '../../../lib/types';
import MainNavArrowImgGreen from './main_nav_arrow_green.svg';
import MainNavArrowImgWhite from './main_nav_arrow_white.svg';
import MainNavCheckImgOrange from './main_nav_check_orange.svg';
import MainNavCheckImgWhite from './main_nav_check_white.svg';
import { isMyPathVA } from '../../../lib/appMode';

type NavButtonProps = {
  str: string,
  to: string,
  isMainNavMBC: boolean,
  isMainNavMP: boolean,
  isVisited: boolean,
  isWiggling: boolean,
  isHidden: boolean,
  left: boolean,
  index: number,
  style: {} | null,
  additionalClasses: string,
  hiddenText: string | null,
  mainNavVisitMBC: Dispatch,
  mainNavVisitMP: Dispatch,
  navLog: Dispatch,
  linkRef: Object | null,
};

let MainNavArrowImg;
let MainNavCheckImg;

if (isMyPathVA) {
  MainNavArrowImg = MainNavArrowImgGreen;
  MainNavCheckImg = MainNavCheckImgOrange;
} else {
  MainNavArrowImg = MainNavArrowImgWhite;
  MainNavCheckImg = MainNavCheckImgWhite;
}

const imgClassNameGenerator = (isVisited: boolean, isWiggling: boolean): string => {
  if (isVisited) return 'main-nav-check';
  if (isWiggling) return 'main-nav-arrow wiggle';
  return 'main-nav-arrow';
};

const divClassNameGenerator = (isHidden: boolean, isVisited: boolean, left: boolean, additionalClasses: string): string => {
  let output = 'nav-button';
  if (isHidden) output += ' hidden';
  else if (isVisited) output += ' visited';
  if (left) output += ' left';
  return `${output} ${additionalClasses}`;
};

const strGenerator = (str: string, isMainNavMBC: boolean, left: boolean): string => {
  if (left) return `« ${str}`;
  if (!isMainNavMBC) return `${str} »`;
  return str;
};

const NavButton = ({
  str,
  hiddenText,
  to,
  isMainNavMBC = false,
  isMainNavMP = false,
  isVisited = false,
  isWiggling = false,
  isHidden = false,
  linkRef,
  left = false,
  style = null,
  additionalClasses = '',
  mainNavVisitMBC,
  mainNavVisitMP,
  navLog,
  index = 0,
}: NavButtonProps): React.Element<'div'> => {
  const label = to.split('/').slice(-1);

  const clickHandler = () => {
    if (isMainNavMBC === true) mainNavVisitMBC(index);
    else if (isMainNavMP === true) mainNavVisitMP(index);
    navLog(str, window.location.pathname);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={divClassNameGenerator(
        isHidden,
        isVisited,
        left,
        additionalClasses
      )}
      onClick={clickHandler}
      role="menuitem"
      aria-labelledby={`${label}-label`}
      style={style}
      tabIndex="-1"
    >
      <Link to={to} id={`${label}-label`} ref={linkRef}>
        <p>{strGenerator(str, isMainNavMBC, left)}</p>
        {hiddenText ? <span className="screen-reader-only">{hiddenText}</span> : null}
        {
          (isMainNavMBC || isMainNavMP) ? (
            <img
              className={imgClassNameGenerator(isVisited, isWiggling)}
              alt={
                // eslint-disable-next-line no-nested-ternary
                isVisited
                  ? 'visited'
                  : isWiggling
                    ? 'next suggested topic'
                    : 'unvisited'
              }
              src={isVisited ? MainNavCheckImg : MainNavArrowImg}
            />
          ) : null
        }
      </Link>
    </div>
  );
};

const mapDispatchToProps = navActions;

export default connect(null, mapDispatchToProps)(NavButton);
