// @flow

import React from 'react';
import type { PageProps } from '../../../lib/types';
import IntroPage from '../../shared/IntroPage';
import './IntroPeripartum.scss';

const Intro = (props: PageProps): React.Element<*> => (
  <IntroPage nextPage="main_nav" {...props} />
);

export default Intro;
