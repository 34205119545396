// @flow

import React, { useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter, matchPath } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ifvisible from 'ifvisible';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { persistor, store } from '../lib/store';
import { pagesLexicon } from '../lib/lexiconConfig';
import {
  isMyPath,
  isMyPathPeripartum,
  isInControl,
  isMyBCPeripartum,
  isImpact,
} from '../lib/appMode';
import {
  myBCRoutes,
  myPathRoutes,
  peripartumRoutes,
  myPathPeripartumRoutes,
  inControlRoutes,
} from '../routes';

import Rainbow from './shared/rainbow/Rainbow';
import InactivityModal from './shared/InactivityModal';
import Pages from './Pages';

import './App.scss';

type AppProps = {
  lexicon: Lexicon,
};

/* [Environment setup] */
let routes = myBCRoutes;

if (isMyPathPeripartum) routes = myPathPeripartumRoutes;
else if (isInControl) routes = inControlRoutes;
else if (isMyPath) routes = myPathRoutes;
else if (isMyBCPeripartum) routes = peripartumRoutes;

let minimizeCheckInterval;

const AppHelpers = {
  fetchNewUser: () => {
    fetch('/users', { method: 'POST' })
      .then((res: string): {} => res.json())
      .then((json: {}) => {
        store.dispatch({
          type: 'SET_USER_ID',
          value: json.id,
        });
      })
      .catch((err: Error) => {
        console.warn(err);
      });
  },
  handleImagePreload: () => {
    const importAll = (r: Object): Array<string> => r.keys().map(r);
    const imageArr = importAll(
      require.context('.', true, /\.(png|jpe?g|svg)$/)
    );

    setTimeout(() => {
      imageArr.forEach((imageSrc: string) => {
        const preloadImage = new Image();
        preloadImage.src = imageSrc;
      });
    }, 1000);
  },
  handleAppResetIfMinimized: () => {
    minimizeCheckInterval = setInterval(() => {
      if (
        ifvisible.now('hidden') &&
        window.location.pathname !== '/en/splash'
      ) {
        window.location.href = `https://${window.location.host}`;
      }
    }, 1000);
  },
  handleReduxPersist: async () => {
    const { pathname } = window.location;
    const isOnFriendlyIdRoute = matchPath(
      '/start/:friendly_id',
      pathname
    )?.params;
    const isOnFormRoute = matchPath('/form', pathname);

    // impact must start on form route
    if (isImpact) {
      // FormMP will handle purging
      if (isOnFormRoute) return;
      else window.location.href = `https://${window.location.host}/form`;
    } else {
      // StartMP will handle purging
      if (isOnFriendlyIdRoute) return;
      // root path: clear local storage, fetch new user id
      else if (pathname === '/en/splash') {
        await persistor.purge();
        AppHelpers.fetchNewUser();
      }
    }
  },

  /* [508 accessbility]
      add/remove .using-mouse class based on user input via keyboard/mouse;
      this class is used to manage the viability of :focus outline */
  addInputDeviceListeners: () => {
    document.body.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });
    document.body.addEventListener('keydown', () => {
      document.body.classList.remove('using-mouse');
    });
  },
};

const App: React.FC = ({ lexicon }: AppProps) => {
  const [isInactivityModalOn, setIsInactivityModalOn] = useState(false);

  /* state handlers */
  const toggleOffModal = () => setIsInactivityModalOn(false);
  const onIdle = () => {
    const isAppRunInClinics = !isMyPath && !isInControl;
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (
      window.location.pathname !== '/' &&
      isAppRunInClinics &&
      windowWidth > 719
    ) {
      setIsInactivityModalOn(true);
    }
  };

  useEffect(() => {
    AppHelpers.handleReduxPersist();
    AppHelpers.handleImagePreload();
    AppHelpers.addInputDeviceListeners();
    if (!isImpact) AppHelpers.handleAppResetIfMinimized();
  }, []);

  return (
    <HelmetProvider>
      <IdleTimer
        element={document}
        onActive={toggleOffModal}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 2}
      />
      {isInactivityModalOn && (
        <InactivityModal
          clickHandler={toggleOffModal}
          handleTurningOffModal={toggleOffModal}
        />
      )}

      <Rainbow />

      <BrowserRouter>
        <Pages fullLexicon={lexicon} routes={routes} />
      </BrowserRouter>
    </HelmetProvider>
  );
};

const EditableApp = (): React.Element => (
  <EditWrapper
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL}
    lexicon={pagesLexicon}
    component={App}
  />
);

export default EditableApp;
