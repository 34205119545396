// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import NavContainer from '../../shared/nav/NavContainer';
import noteActions from '../../../actions/noteActions';
import type { PageProps, State } from '../../../lib/types';
import './GotQuestions.scss';

type GotQuestionsProps = PageProps & {
  notesInRedux: string,
  navLinks: [string],
};

const GotQuestions = ({
  lexicon,
  sharedLexicon,
  locale,
  navLinks,
  onSave,
  notesInRedux
}: GotQuestionsProps): React.Element<*> => {
  const headingMD = `# ${sharedLexicon.get('notes.headerText')}`;

  return (
    <>
      <Headings isAutoFocusing str={headingMD} />
      <MarkdownBlock str={sharedLexicon.get('notes.longDescriptionFirst')} />
      <MarkdownBlock str={sharedLexicon.get('notes.longDescriptionSecond')} />
      <div className="textarea-container">
        {notesInRedux === '' && (
          <div className="placeholder" aria-hidden>
            <MarkdownBlock str={sharedLexicon.get('notes.placeholder')} />
          </div>
        )}
        <textarea
          value={notesInRedux}
          onChange={(e: React.SyntheticEvent) => { onSave(e.target.value); }}
          placeholder={notesInRedux || sharedLexicon.get('notes.placeholder')}
        />
      </div>
      <NavContainer
        locale={locale}
        nextPage={navLinks[1]}
        backPage={navLinks[0]}
        notesContent={notesInRedux}
        sharedLexicon={sharedLexicon}
        nextText={notesInRedux ? null : lexicon.get('noQuestionsNextText')}
        hideNotes
      />
    </>
  );
};

const mapStateToProps = (state: State): Object => ({ notesInRedux: state.notesReducer.notes });

const mapDispatchToProps = noteActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GotQuestions);
