// @flow

import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers/index';
import { reduxAnalytics } from './reduxAnalytics';
import type { Store } from './types';

const persistConfig = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAnyModalVisible', 'isShowingRecs'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = [
  reduxAnalytics
];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, createLogger()];
}

export const store: Store = createStore(persistedReducer, {}, applyMiddleware(...middleware));
export const persistor = persistStore(store);
