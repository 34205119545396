// @flow

import React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock } from '../shared/textComponents/TextComponents';
import SexAfterBaby from '../peripartum/SexAfterBaby/SexAfterBaby';
import Topic from '../shared/topic/Topic';
import type { PageProps, State } from '../../lib/types';

const SexAfterBabyMP: React.FC = (props: PageProps) => {
  const { isAnyModalVisible, lexicon, locale } = props;

  return (
    <SexAfterBaby {...props}>
      <MarkdownBlock ariaHidden={isAnyModalVisible} str={lexicon.get('paragraph_1')} />
      { lexicon.get('topics_1').map((topic: string, index: number): React.element<*> => (
        <Topic
          key={topic}
          topic={topic}
          locale={locale}
          ariaHidden={props.isAnyModalVisible}
          isFirst={index === 0}
        />
      ))}
      <MarkdownBlock ariaHidden={isAnyModalVisible} str={lexicon.get('paragraph_2')} />
      { lexicon.get('topics_2').map((topic: string): React.element<*> => (
        <Topic
          key={topic}
          topic={topic}
          locale={locale}
          ariaHidden={props.isAnyModalVisible}
        />
      ))}
      <div className="left-align">
        <MarkdownBlock ariaHidden={isAnyModalVisible} str={lexicon.get('paragraph_3')} />
      </div>
    </SexAfterBaby>
  );
};

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(SexAfterBabyMP);
