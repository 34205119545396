// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import { isMyPath4you, isMyPathVA, isInControl } from '../../../lib/appMode';
import { MarkdownBlock } from '../../shared/textComponents/TextComponents';
import StudyIdImage from '../../mypath/StudyMP/StudyIdImage';
import EmailIcon from './email-icon.svg';

type SummaryDescriptionsProps = {
  lexicon: Lexicon,
  isAnyModalVisible: boolean,
  userId: string,
  modalClickHandler: () => void,
  emailButtonRef: Ref,
  locale: 'en' | 'es',
};

const SummaryDescriptions: React.FC = ({
  lexicon,
  isAnyModalVisible,
  userId,
  modalClickHandler,
  emailButtonRef,
  locale,
}: SummaryDescriptionsProps) => {
  if (isMyPathVA) return <MarkdownBlock id="mypath_description" str={lexicon.get('description')} ariaHidden={isAnyModalVisible} />;
  if (!isMyPath4you && !isInControl) return <div className="description" aria-hidden={isAnyModalVisible}>{lexicon.get('description')}</div>;

  const description1 = isMyPath4you
    ? lexicon.get('path4you.description1')
    : lexicon.get('description1');

  const description2 = isMyPath4you
    ? lexicon.get('path4you.description2')
    : lexicon.get('description2');

  const scheduleAppointmentButton = isMyPath4you
    ? lexicon.get('path4you.scheduleAppointment')
    : lexicon.get('scheduleAppointment');

  const description3 = isMyPath4you
    ? lexicon.get('path4you.description3')
    : lexicon.get('description3');

  const testIdText = locale === 'es' ? 'identificación de prueba' : 'test-id';

  return (
    <div className="description" aria-hidden={isAnyModalVisible}>
      <p>{description1}</p>
      <div className="path4you-button-container" style={isAnyModalVisible ? { display: 'none' } : null} >
        <button
          className="summary-button email"
          type="button"
          aira-label={lexicon.get('emailButton')}
          onClick={modalClickHandler}
          ref={emailButtonRef}
          tabIndex={isAnyModalVisible ? null : '0'}
        >
          <img src={EmailIcon} alt="" aria-hidden />
          <p>{lexicon.get('emailButton')}</p>
        </button>
      </div>
      <p>{description2}</p>
      <div className="path4you-button-container" style={isAnyModalVisible ? { display: 'none' } : null} >
        <a className="summary-button return-to-path4you" href={`${process.env.REACT_APP_RETURN_LINK}`} >
          <p>{scheduleAppointmentButton}</p>
        </a>
      </div>
      <div className="path4you-study-info">
        <MarkdownBlock str={description3} />
        <StudyIdImage userId={userId || testIdText} size="mini" />
      </div>
    </div>
  );
};

export default SummaryDescriptions;
