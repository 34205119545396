// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import MenuItem from '../../shared/menuItem/MenuItem';
import afterBabyActions from '../../../actions/afterBabyActions';
import type { PageProps, State } from '../../../lib/types';
import './SexAfterBaby.scss';

type SexAfterBabyProps = PageProps & {
  afterBabyTopics: {},
};

type SexAfterBabyState = {
  visibleModal: string,
  isAnyModalVisible: boolean,
};

class SexAfterBaby extends React.Component<SexAfterBabyProps, SexAfterBabyState> {
  constructor(props: SexAfterBabyProps) {
    super(props);
    this.state = { visibleModal: null };
  }

  showModal = (item: string) => {
    const { onModalShow } = this.props;
    this.setState({ visibleModal: item });
    if (onModalShow) { onModalShow(item); }
  };

  hideModal = () => {
    this.setState({ visibleModal: null });
  }

  render() {
    const { locale, afterBabyTopics, isAnyModalVisible, lexicon, sharedLexicon, children } = this.props;
    const { visibleModal } = this.state;

    const menuItemProps = (item: string): {} => ({
      question: lexicon.get(`${item}_topic`),
      modalCopy: lexicon.get(`${item}_modal_copy`),
      modalVisible: visibleModal === item,
      ariaHidden: isAnyModalVisible,
      onModalShow: () => { this.showModal(item); },
      onModalHide: this.hideModal,
      visited: !!afterBabyTopics[item],
      buttonText: lexicon.get('buttonText'),
      sharedLexicon,
      locale,
    });

    return (
      <>
        <Headings ariaHidden={isAnyModalVisible} isAutoFocusing str={lexicon.get('headings')} />
        { children || <MarkdownBlock ariaHidden={isAnyModalVisible} str={lexicon.get('paragraphs')} /> }
        <div className="menu-container">
          <MenuItem {...menuItemProps('partner_talk')} className="first" />
          <MenuItem {...menuItemProps('listen_body')} className="third" />
          <MenuItem {...menuItemProps('real_talk')} className="fourth" />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  afterBabyTopics,
  isAnyModalVisible,
}: StateType): State => ({
  afterBabyTopics,
  isAnyModalVisible,
});

const mapDispatchToProps = afterBabyActions;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SexAfterBaby);
