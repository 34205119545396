// @flow

import React from 'react';
import { connect } from 'react-redux';
import ReactSlider from 'react-slider';
import { MarkdownBlock, Headings } from '../shared/textComponents/TextComponents';
import SurveyActions from '../../actions/SurveyActions';
import NotesActions from '../../actions/noteActions';
import './BarrierSurvey.scss';
import type { State, PageProps, Dispatch } from '../../lib/types';

type BarrierSurveyProps = PageProps & {
  answerBarrierSurvey: Dispatch,
  setInControlNotes: Dispatch,
  barrierSurvey: Array<number>,
  inControlNotes: string,
};

const BarrierSurvey: React.FC = ({
  lexicon,
  sharedLexicon,
  isAnyModalVisible,
  answerBarrierSurvey,
  barrierSurvey,
  setInControlNotes,
  inControlNotes
}: BarrierSurveyProps) => (
  <>
    <Headings str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
    <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />

    <div className="survey-container" ariaHidden={isAnyModalVisible}>
      { sharedLexicon
        .get('barrierSurveyQuestions')
        .map((question: string, index: number): React.Element<'div'> => (
          <div className="slider-and-question" key={question}>
            <h2>{question}</h2>
            <div className="slider-and-labels">
              <ReactSlider
                min={0}
                max={4}
                marks={[0, 1, 2, 3, 4]}
                markClassName="mark"
                thumbClassName="thumb"
                trackClassName="track"
                value={barrierSurvey[index]}
                onChange={(newState: number): void => answerBarrierSurvey(index, newState)}
                renderThumb={(props: *): React.Element<'div'> => <div {...props}><div className="thumb-solid" /></div>}
              />
              <div className="flex-group">
                { sharedLexicon.get('barrierSurveyValues').map((v: string): React.Element<'div'> => (
                  <div className="slider-label-container" key={v}>
                    <div className="slider-label" key={v}>
                      {v}
                    </div>
                  </div>
                )) }
              </div>
            </div>
          </div>
        ))}
    </div>

    <MarkdownBlock str={lexicon.get('navigatorBox')} ariaHidden={isAnyModalVisible} />
    <div className="textarea-container">
      <textarea
        value={inControlNotes}
        onChange={(e: React.SyntheticEvent) => { setInControlNotes(e.target.value); }}
      />
    </div>
  </>
);

const mapStateToProps = ({
  barrierSurvey,
  isAnyModalVisible,
  notesReducer: { inControlNotes }
}: State): { barrierSurvey: Array<number> } => ({
  barrierSurvey,
  inControlNotes,
  isAnyModalVisible
});
const mapDispatchToProps = { ...SurveyActions, ...NotesActions };

export default connect(mapStateToProps, mapDispatchToProps)(BarrierSurvey);
