// @flow

import React, { createRef, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import sideEffectsAction from '../../../actions/sideEffectsActions';
import type { State, MapStateToProps, Dispatch } from '../../../lib/types';

type Props = {
  categoryNum: number | null,
  categoryNames: Array<string>,
  clickHandler: number => void,
  categoryVisit: Dispatch,
  sideEffectsCategoryVisitedArr: Array<boolean>,
  prevCategoryNum: null | number,
  mobileButtonLabel: string,
  isAnyModalVisible: boolean,
  isMobile: boolean,
};

const CategoryBar = ({
  categoryNum,
  categoryNames,
  clickHandler,
  categoryVisit,
  sideEffectsCategoryVisitedArr,
  mobileButtonLabel = '',
  isAnyModalVisible,
  prevCategoryNum,
  isMobile = false
}: Props): React.Element<'div'> => {
  const refs = useRef(new Array(categoryNames.length).fill(0).map((): {} => createRef()));

  useEffect(() => {
    if (isMobile) {
      if (Number.isInteger(prevCategoryNum)) refs.current[prevCategoryNum].current.focus();
    }
  }, []);

  useEffect(() => {
    if (!isMobile && Number.isInteger(categoryNum)) refs.current[categoryNum].current.focus();
  }, [categoryNum]);

  const keyPressHandler = (evt: React.SyntheticEvent) => {
    const keyNum = (evt.which) ? evt.which : evt.keyCode;

    if (categoryNum !== null) {
      switch (keyNum) {
        case 39: // right
          if (categoryNum === categoryNames.length - 1) clickHandler(0);
          else clickHandler(categoryNum + 1);
          break;
        case 37: // left
          if (categoryNum === 0) clickHandler(categoryNames.length - 1);
          else clickHandler(categoryNum - 1);
          break;
        case 36: // home
          clickHandler(0);
          break;
        case 35: // end
          clickHandler(categoryNames.length - 1);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="category-bar" role="tablist">
      {
        categoryNames.map((name: string, i: number): React.Element<'button'> => {
          let tabIndex = '0';
          if (isAnyModalVisible) tabIndex = '-1';
          else if (categoryNum !== null && categoryNum !== i) tabIndex = '-1';

          const className = `selection-button ${i === categoryNum ? 'selected' : ''} ${sideEffectsCategoryVisitedArr[i] ? 'visited' : ''}`;

          return (
            <button
              className={className}
              aria-controls="category-intro-text"
              aria-label={`${name}, ${mobileButtonLabel}`}
              key={name}
              onClick={() => { clickHandler(i); categoryVisit(i); }}
              onKeyDown={keyPressHandler}
              aria-hidden={isAnyModalVisible}
              tabIndex={tabIndex}
              role="tab"
              type="button"
              aria-selected={i === categoryNum}
              id={`${name}-tab`}
              ref={refs.current[i]}
            >
              {name}
            </button>
          );
        })
      }
    </div>
  );
};
// }

const mapDispatchToProps = sideEffectsAction;
const mapStateToProps: MapStateToProps<State> = ({
  sideEffectsCategoryVisitedArr,
  isAnyModalVisible
}: State): Object => ({
  sideEffectsCategoryVisitedArr,
  isAnyModalVisible
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryBar);
