// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import Topic from '../../shared/topic/Topic';
import type { PageProps, State } from '../../../lib/types';
import './HealthPregnancyTopics.scss';

class HealthPregnancyTopics extends React.PureComponent<PageProps> {
  componentWillUnmount() { this.props.hideInstructions(); }

  render() {
    const {
      isAnyModalVisible,
      hideInstructions,
      lexicon,
      locale,
    } = this.props;

    return (
      <div className="HealthPregnancyMenu">
        <Headings
          isAutoFocusing
          str={lexicon.get('.headings')}
          ariaHidden={isAnyModalVisible}
        />
        <MarkdownBlock str={lexicon.get('.paragraphs')} ariaHidden={isAnyModalVisible} />
        <div className="topics" role="presentation" onClick={hideInstructions}>
          {
            lexicon.get('.topics').map((topic: string, i: number): React.Element<*> => (
              <Topic 
                key={topic}
                topic={topic}
                locale={locale}
                ariaHidden={isAnyModalVisible}
                isFirst={i === 0}
              />
            ))
          }
        </div>
        <MarkdownBlock str={lexicon.get('.bottomText')} ariaHidden={isAnyModalVisible} />
      </div>
    );
  }
}

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = { hideInstructions: (): Action => ({ type: 'HIDE_TOPIC_MODAL' }) };

export default connect(mapStateToProps, mapDispatchToProps)(HealthPregnancyTopics);
