// MyPath Postpartums
const mypathPeripartumModes = ['mypathPeripartum', 'mypathPeripartumVA', 'impact'];
export const isMyPathPeripartum = mypathPeripartumModes.includes(process.env.REACT_APP_MODE);
export const isMyPathPeripartumVA = process.env.REACT_APP_MODE === 'mypathPeripartumVA';
export const isImpact = process.env.REACT_APP_MODE === 'impact';

// regular MyPaths
const myPathModes = ['mypathGeneral', 'mypathVA', 'mypathVAStudy', 'mypath4you', 'mypathPeripartum', 'mypathPeripartumVA', 'impact'];
export const isMyPath = myPathModes.includes(process.env.REACT_APP_MODE);

const myPathVAModes = ['mypathVA', 'mypathVAStudy', 'mypathPeripartumVA'];
export const isMyPathVA = myPathVAModes.includes(process.env.REACT_APP_MODE);
export const isMyPathVAStudy = process.env.REACT_APP_MODE === 'mypathVAStudy';

const myPathGeneralModes = ['mypathGeneral', 'mypath4you', 'mypathPeripartum'];
export const isMyPathGeneral = myPathGeneralModes.includes(process.env.REACT_APP_MODE);
export const isMyPath4you = process.env.REACT_APP_MODE === 'mypath4you';

// MyBC Peripartums

const myBCPeripartumModes = ['peripartum', 'peripartumUTA'];
export const isMyBCPeripartum = myBCPeripartumModes.includes(process.env.REACT_APP_MODE);
export const isMyBCPeripartumUTA = process.env.REACT_APP_MODE === 'peripartumUTA';

// regular MyBCs
const myBCModes = ['mybc', 'peripartum', 'peripartumUTA', 'inControl'];
export const isMyBC = myBCModes.includes(process.env.REACT_APP_MODE);
export const isInControl = process.env.REACT_APP_MODE === 'inControl';

// others
const appsWith508Colors = ['mypathVA', 'mypathVAStudy', 'mypathPeripartumVA']
export const isUsing508Colors = appsWith508Colors.includes(process.env.REACT_APP_MODE);
export const isStudy = process.env.REACT_APP_STUDY === 'study';
