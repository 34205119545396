import React from 'react';
import CalendarBar from './CalendarBar';

type CalendarBarManagerProps = {
  num: number | null,
};

export const methods = [
  'femaleCondom',
  'maleCondom',
  'diaphragm',
  'pill',
  'patch',
  'ring',
  'shot',
  'implant',
  'iud',
  'sterilization',
];

const CalendarBarManager = ({
  num,
  locale
}: CalendarBarManagerProps): React.Node => {
  const activeMethodClassName = num === null ? '' : methods[num];

  if (num === null) return null;
  if (num < 6) {
    return <div className={`calendar ${activeMethodClassName}`} />;
  }
  if (num === 6) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={3}
        duration={12}
        locale={locale}
        isMonthBar
      />
    );
  }
  if (num === 7) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={5}
        duration={7}
      />
    );
  }
  if (num === 8) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={12}
        duration={13}
      />
    );
  }
  return (
    <CalendarBar
      activeMethodClassName={activeMethodClassName}
      freq={100}
      duration={10}
      isInfinity
    />
  );
};

export default CalendarBarManager;