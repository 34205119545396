// @flow
import type { SurveyStateMBC } from '../lib/types';
import { questionConfig1 } from '../components/mybc/surveyMBC/Survey1MBC';
import { questionConfig2 } from '../components/mybc/surveyMBC/Survey2MBC';
import { questionConfig3 } from '../components/mybc/surveyMBC/Survey3MBC';
import { questionConfig4 } from '../components/mybc/surveyMBC/Survey4MBC';
import { questionConfig5 } from '../components/mybc/surveyMBC/Survey5MBC';

type SurveyAction = {
  +questionKey: string,
  +type: string,
  +value: string,
  +category?: string,
};

type QuestionTypeConfig = {
  type: string,
  questionKey: string,
  choiceKeys: Array<string>,
  subkeys?: Array<string>,
  prefill?: string,
};

type QuestionTypeConfigArr = Array<QuestionTypeConfig>;

const questionTypeConfig: QuestionTypeConfigArr = [
  ...questionConfig1, ...questionConfig2, ...questionConfig3, ...questionConfig4, ...questionConfig5,
];
// console.log('questionTypeConfig', questionTypeConfig);

const prefillPluralFunc = (prefill: void | string): null | Array<string> => (prefill ? [prefill] : null);
const prefillMultiFunc = (prefill: void | string, config: Object): Array<string> => (
  config.subkeys.reduce((accu: {}, choiceKey: string): Object => ({ ...accu, [choiceKey]: prefill || null }), {})
);
const prefillSingleFunc = (prefill: void | string): null | string => (prefill || null);

const initialState = {};
questionTypeConfig.forEach((config: QuestionTypeConfig) => {
  const { prefill, type, questionKey } = config;
  if (type === 'plural') {
    initialState[questionKey] = prefillPluralFunc(prefill);
  } else if (type === 'multi') {
    initialState[questionKey] = prefillMultiFunc(prefill, config);
  } else {
    initialState[questionKey] = prefillSingleFunc(prefill);
  }
});

// this line is necessary for the algorithm
initialState.adminEnthusiasms.penile = 'negative';
initialState.migraine = [];

// console.log(initialState);

// ========================
const prefilledInitialState = {};

prefilledInitialState.sideEffectBothers = {
  spotting: 'negativeWeak',
  noPeriod: 'neutral',
  heavierPeriod: 'negative',
  weightGain: 'neutral'
};

prefilledInitialState.effectiveness = 'very';
prefilledInitialState.timing = 'never';
prefilledInitialState.admin = 'positive';
prefilledInitialState.frequency = 'positive';
prefilledInitialState.adminEnthusiasms = {
  oral: 'negative',
  topical: 'negative',
  vaginal: 'positive',
  penile: 'negative',
  injection: 'positive',
  uterine: 'negative',
  subcutaneous: 'negative',
  surgical: 'negative'
};

prefilledInitialState.benefitEnthusiasms = {
  decreasedAcne: 'positiveWeak',
  noPeriod: 'neutral',
  decreasedCramping: 'negative',
  decreasedPeriod: 'positive'
};

prefilledInitialState.sideEffectBothers = {
  spotting: 'neutral',
  noPeriod: 'negative',
  heavierPeriod: 'negativeWeak',
  weightGain: 'positive'
};

prefilledInitialState.concerns = ['migraine', 'over35', 'smoking'];
prefilledInitialState.migraine = [];
prefilledInitialState.frequencyEnthusiasms = {
  session: 'neutral',
  day: 'neutral',
  week: 'neutral',
  month: 'neutral',
  quarter: 'neutral',
  superyear: 'neutral',
  permanent: 'neutral'
};

prefilledInitialState.feelAboutMethods = {
  Sterilization: 'never',
  'Hormonal IUD': 'never',
  'Copper IUD': 'never',
  Implant: 'never',
  Shot: 'never',
  Ring: 'never',
  Patch: 'never',
  Pill: 'never',
  Diaphragm: 'never',
  'Male Condom': 'never',
  'Internal Condom': 'never',
};

// console.log('after initialing', initialState)

const surveyReducerMBC = (state: SurveyStateMBC = initialState, action: SurveyAction): SurveyStateMBC => {
  const { value, questionKey, type, category } = action;
  const newAnswers = { ...state };

  switch (type) {
    case 'ANSWER_SURVEY_SINGLE_MBC':
      newAnswers[questionKey] = value;
      return newAnswers;

    case 'ANSWER_SURVEY_PLURAL_MBC':
      if (newAnswers[questionKey] === null && value !== 'none') newAnswers[questionKey] = [];
      if (value === 'none') {
        if (newAnswers[questionKey] === null || newAnswers[questionKey].length) newAnswers[questionKey] = [];
        else newAnswers[questionKey] = null;
      } else if (newAnswers[questionKey].includes(value)) newAnswers[questionKey].splice(newAnswers[questionKey].indexOf(value), 1);
      else newAnswers[questionKey].push(value);
      return newAnswers;

    case 'ANSWER_SURVEY_MULTI_MBC':
      newAnswers[questionKey][category] = value;
      return newAnswers;

    case 'PREFILL_SURVEY4':
      if (Object.values(newAnswers.feelAboutMethods).every((answer: string | null): boolean => answer === null)) newAnswers.feelAboutMethods = prefilledInitialState.feelAboutMethods;
      return newAnswers;

    case 'SECRET_PREFILL_SURVEY':
      return prefilledInitialState;

    default:
      return state;
  }
};

export default surveyReducerMBC;
