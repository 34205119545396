// @flow

import React from 'react';
import { LI } from '../../shared/textComponents/TextComponents';

type StarLegendProps = {
  headingText: string,
  listArr: Array<string>,
  ariaHidden: boolean,
};

const StarLegend = ({ headingText, listArr, ariaHidden }: StarLegendProps): React.Element<'div'> => (
  <div className="star-legend-container" aria-hidden={ariaHidden}>
    <h2 className="star-legend-heading">{headingText}</h2>
    <ul>
      {
        listArr.map((item: string): React.Element<'LI'> => <LI key={item} str={item} />)
      }
    </ul>
  </div>
);

export default StarLegend;
