// @flow

import React from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import type { PageProps, State } from '../../../lib/types';
import './FertilityFuture.scss';

const FutilityFuture = ({ lexicon, isAnyModalVisible }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
    <div aria-hidden className="image-space" />
    <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
  </>
);

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(FutilityFuture);
