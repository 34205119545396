// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import type { PageProps, State } from '../../../lib/types';

const FertilityAwareness = ({ isAnyModalVisible, lexicon }: PageProps): React.Element<*> => (
  <>
    <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
    <div className="flex-group">
      <div aria-hidden className="ellipse-space" />
      <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
    </div>
  </>
);

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });

export default connect(mapStateToProps)(FertilityAwareness);
