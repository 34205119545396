// @flow

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MarkdownBlock, Headings } from '../../shared/textComponents/TextComponents';
import SingleRadioSurveyQuestion from '../../shared/survey/SingleRadioSurveyQuestion';
import MultiPartSurveyQuestion from '../../shared/survey/MultiPartSurveyQuestion';
import { isQuestionFullyAnsweredFuncMBC } from '../../../lib/utils';
import NavContainer from '../../shared/nav/NavContainer';
import SurveyActions from '../../../actions/SurveyActions';
import noteActions from '../../../actions/noteActions';
import type { SurveyMBCProps, State, MapStateToProps } from '../../../lib/types';
import '../../shared/survey/SurveyPage.scss';

export const questionConfig1 = [
  { type: 'single', questionKey: 'timing', choiceKeys: ['never', 'subYear', 'superYear', 'SUPERYEAR'] },
  { type: 'single', questionKey: 'effectiveness', choiceKeys: ['not', 'somewhat', 'very'] },
  { type: 'single', questionKey: 'frequency', choiceKeys: ['negative', 'neutral', 'positive'] },
  {
    type: 'multi',
    questionKey: 'frequencyEnthusiasms',
    subkeys: ['session', 'day', 'week', 'month', 'quarter', 'superyear', 'permanent'],
    choiceKeys: ['positive', 'neutral', 'negative'],
  },
];

const Survey1MBC = ({
  locale,
  navLinks,
  surveyStateMBC,
  answerSurveySingleMBC,
  answerSurveyMultiMBC,
  isAnyModalVisible,
  onHideInstructions,
  lexicon,
  sharedLexicon,
}: SurveyMBCProps): React.node<*> => {
  useEffect(() => {
    onHideInstructions();
  }, []);

  const isNextButtonHidden = !questionConfig1.every(({ questionKey }: Object): boolean => isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionKey]));

  return (
    <>
      <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
      <h2 className="screen-reader-only">{lexicon.get('hiddenText')}</h2>
      <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />

      <div className="survey-questions-container">
        <SingleRadioSurveyQuestion
          texts={sharedLexicon.get('survey1MbcQuestions.0.texts')}
          choices={sharedLexicon.get('survey1MbcQuestions.0.choices')}
          ariaHidden={isAnyModalVisible}
          questionIndex={0}
          selectedChoiceIndex={['never', 'subYear', 'superYear', 'SUPERYEAR'].indexOf(surveyStateMBC.timing)}
          clickHandler={(choiceIndex: number) => {
            answerSurveySingleMBC('timing', ['never', 'subYear', 'superYear', 'SUPERYEAR'][choiceIndex]);
          }}
        />
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[0].questionKey]) && (
            <SingleRadioSurveyQuestion
              texts={sharedLexicon.get('survey1MbcQuestions.1.texts')}
              choices={sharedLexicon.get('survey1MbcQuestions.1.choices')}
              ariaHidden={isAnyModalVisible}
              questionIndex={1}
              selectedChoiceIndex={['not', 'somewhat', 'very'].indexOf(surveyStateMBC.effectiveness)}
              clickHandler={(choiceIndex: number) => {
                answerSurveySingleMBC('effectiveness', ['not', 'somewhat', 'very'][choiceIndex]);
              }}
            />
          )
        }
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[1].questionKey]) && (
            <SingleRadioSurveyQuestion
              texts={sharedLexicon.get('survey1MbcQuestions.2.texts')}
              choices={sharedLexicon.get('survey1MbcQuestions.2.choices')}
              ariaHidden={isAnyModalVisible}
              questionIndex={2}
              selectedChoiceIndex={['negative', 'neutral', 'positive'].indexOf(surveyStateMBC.frequency)}
              clickHandler={(choiceIndex: number) => {
                answerSurveySingleMBC('frequency', ['negative', 'neutral', 'positive'][choiceIndex]);
              }}
            />
          )
        }
        {
          isQuestionFullyAnsweredFuncMBC(surveyStateMBC[questionConfig1[2].questionKey]) && (
            <MultiPartSurveyQuestion
              texts={sharedLexicon.get('survey1MbcQuestions.3.texts')}
              choices={sharedLexicon.get('survey1MbcQuestions.3.choices')}
              categories={sharedLexicon.get('survey1MbcQuestions.3.categories')}
              ariaHidden={isAnyModalVisible}
              subkeys={['session', 'day', 'week', 'month', 'quarter', 'superyear', 'permanent']}
              questionKey="frequencyEnthusiasms"
              choiceKeys={['positive', 'neutral', 'negative']}
              answerSurveyMultiMBC={answerSurveyMultiMBC}
              currentChoice={surveyStateMBC.frequencyEnthusiasms}
            />
          )
        }
      </div>

      <NavContainer
        locale={locale}
        isHidingNextButton={isNextButtonHidden}
        nextPage={navLinks[1]}
        sharedLexicon={sharedLexicon}
      />
    </>
  );
};

const mapStateToProps: MapStateToProps<State> = ({
  surveyStateMBC,
  isAnyModalVisible,
}: State): Object => ({
  surveyStateMBC,
  isAnyModalVisible,
});
const mapDispatchToProps = { ...SurveyActions, ...noteActions };

export default connect(mapStateToProps, mapDispatchToProps)(Survey1MBC);
