/* eslint-disable */
// @flow

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ifvisible from 'ifvisible';
import NavButton from './nav/NavButton';
import SurveyActions from '../../actions/SurveyActions';
import NavActions from '../../actions/navActions';
import SecretPrefillActions from '../../actions/secretPrefillActions';
import './SecretDeveloperPage.scss';
import { isMyBCPeripartum, isMyPath, isMyPathPeripartum, isInControl } from '../../lib/appMode';

let minimizerCheckFunc;

const sharedRoutes = [
  'main_nav',
  'effectiveness',
  'side_effects',
  'side_effects_per_method',
  'how_to_women',
  'how_to_men',
  'emergency_contraception',
  'fertility_awareness',
  'how_often',
  'fertility_future',
  'survey1_mbc',
  'survey2_mbc',
  'survey3_mbc',
  'survey4_mbc',
  'survey5_mbc',
  'recommendations',
  'compare_methods',
  'summary'
];

const routeKeys = {
  myPathRoutes: [
    'intro_mp',
    'main_nav_mp',
    'survey_mp',
    'current_methods',
    'current_methods_survey',
    'fertility',
    'health_before_pregnancy',
    'health_before_pregnancy_topics',
    ...sharedRoutes
  ],
  myPathPeripartumRoutes: [
    'intro_mp',
    'main_nav_mp',
    'survey_mp',
    'sex_after_baby',
    'fertility_survey_mp',
    'fertility_after_birth',
    'fertility',
    'health_before_pregnancy',
    'health_before_pregnancy_topics',
    'pregnant_again_first',
    'main_nav',
    'important_information',
    'breastfeeding',
    'considerations',
    ...sharedRoutes
  ],
  myBCRoutes: [
    'intro',
    'side_effect_preface',
    'got_questions',
    ...sharedRoutes
  ],

  inControlRoutes: [
    'intro',
    'side_effect_preface',
    'barrier_survey',
    ...sharedRoutes
  ],
  perpartumRoutes: [
    'intro',
    'sex_after_baby',
    'pregnant_again_first',
    'pregnant_again_second',
    'breastfeeding',
    'important_information',
    'considerations',
    'side_effect_preface',
    'got_questions',
    ...sharedRoutes
  ],
};

let routes = routeKeys.myBCRoutes;
if (isMyPathPeripartum) routes = routeKeys.myPathPeripartumRoutes;
else if (isInControl) routes = routeKeys.inControlRoutes;
else if (isMyPath) routes = routeKeys.myPathRoutes;
else if (isMyBCPeripartum) routes = routeKeys.perpartumRoutes;

let timeoutFunc;
let isTimeoutRunning = false;
const locale = "en"

const SecretDeveloperPage2 = props => {
  const [counter, setCounter] = useState(0);
  const [minimizeA, setMinimizeA] = useState([]);
  const [keyNum, setKeyNum] = useState(null);

  const { secretPrefillSurvey, prefillTopics, prefillActiveRecs, prefillCurrentMethods, secretVisitAllMBC } = props;

  const timeoutClickHandler = () => {
    isTimeoutRunning = !isTimeoutRunning;
    if (isTimeoutRunning) {
      timeoutFunc = setInterval(() => {
        setCounter(counter + 1)
      }, 1000);
    } else clearInterval(timeoutFunc);
  };

  const keyPressHandler = evt => {
    const keyNum = (evt.which) ? evt.which : evt.keyCode;
    setKeyNum(keyNum)
  };

  const minimizerCheck = () => {
    minimizerCheckFunc = setInterval(() => {
      if (ifvisible.now('hidden')) setMinimizeA([...minimizeArr, ' hidden']);
      else setMinimizeA([...minimizeArr, ' not-hidden']);
    }, 1000);
  }

  useEffect(() => {
    return () => clearInterval(minimizerCheckFunc);
  }, []);

  return (
    <>
      <div className="dev-options">
        <button type="button" onClick={() => { secretPrefillSurvey(); prefillTopics(); prefillCurrentMethods(); prefillActiveRecs(); secretVisitAllMBC(); }} className="prefill-button" tabIndex="0">
          Prefill both MyBC and MyPath survey, ActiveRecs, MyPath topics, MyPath current methods, visit all MBC main navs
        </button>

        <div>
          <button type="button" onClick={timeoutClickHandler} className="timeout-button">Start timeout</button>
          <div>{`counter is now ${counter}`}</div>
          <button type="button" onKeyDown={keyPressHandler} className="timeout-button">Key Press Test</button>
          <div>{`keyNum is now ${keyNum}`}</div>
        </div>

      </div>
      <div className="nav-buttons-container" id="secret-nav-buttons-container">
        <NavButton str="splash" to="/" />
        {
          routes.map((route: string): React.Element<NavButton> => (
            <NavButton str={route} to={`/${locale}/${route}`} key={route} />
          ))
        }
      </div>
    </>
  );
}

const mapDispatchToProps = { ...SurveyActions, ...SecretPrefillActions, ...NavActions };

export default connect(null, mapDispatchToProps)(SecretDeveloperPage2);
