// @flow

import React from 'react';
import './P4YButton.scss';
import logo from './p4y-logo-0921_pr-wht.png';

type P4YButtonProps = {
  locale: 'en' | 'es',
};

const P4YButton: React.FC = ({ locale }: P4YButtonProps) => (
  <div
    id="p4yr"
    className="path4you-return close"
  >
    <a href={process.env.REACT_APP_RETURN_LINK} className="button">
      <span>{locale === 'es' ? 'salir a' : 'exit to'}</span>
      <img src={logo} alt="PATH4YOU" />
    </a>
  </div>
);

export default P4YButton;
