// @flow

import React from 'react';
import { connect } from 'react-redux';
import {
  Headings,
  MarkdownBlock,
} from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import type { PageProps as Props } from '../../../lib/types';
import './SideEffectsPerMethod.scss';

type StuffContainerProps = {
  +heading: string,
  +stuffType: string,
  list: Array<string>,
};

type SideEffectsPerMethodProps = PageProps & {
  onModalHide: () => {},
  onModalShow: () => {},
};

type State = {
  activeNum: number | null,
};

const methods = [
  'sterilization',
  'hormonalIud',
  'copperIud',
  'implant',
  'shot',
  'ring',
  'patch',
  'pill',
  'diaphragm',
  'maleCondom',
  'femaleCondom',
];

const StuffContainer = ({
  heading,
  list,
  stuffType,
}: StuffContainerProps): React.Element<'div'> => (
  <div className={`stuff-container modal-${stuffType}-stuff-container`}>
    <h2>{heading}</h2>
    <ul className={`${stuffType}-stuff-list`}>
      {list.map((text: string): React.Element<'li'> => (
        <li key={text}>
          <div className="modal-list-bullet" />
          <div className="text">{text}</div>
        </li>
      ))}
    </ul>
  </div>
);

class SideEffectsPerMethod extends React.Component<
  SideEffectsPerMethodProps,
  State,
> {
  constructor(props: Props) {
    super(props);
    this.state = { activeNum: null };
    this.modalHeaderRef = React.createRef();
    this.buttonRefs = [];
    methods.forEach((_: number, i: number) => {
      this.buttonRefs[i] = React.createRef();
    });
  }

  iconClickHandler = (activeNum: number) => {
    const { onModalShow } = this.props;

    this.setState({ activeNum }, () => {
      this.modalHeaderRef.current.focus();
    });
    onModalShow();
  };

  resetActiveNumHandler = () => {
    const { onModalHide } = this.props;
    const { activeNum } = this.state;

    this.setState({ activeNum: null }, () => {
      this.buttonRefs[activeNum].current.focus();
    });
    onModalHide();
  };

  render() {
    const { isAnyModalVisible, sharedLexicon, lexicon, locale } = this.props;
    const { activeNum } = this.state;

    const isModalOn = activeNum !== null;
    const iconNames = methods.map((str: string): string =>
      sharedLexicon.get(`methodNames.${str}`)
    );
    const methodName = sharedLexicon.get(`methodNames.${methods[activeNum]}`);

    return (
      <>
        <Headings
          ariaHidden={isAnyModalVisible}
          isAutoFocusing
          str={lexicon.get('headings')}
        />
        <div role="navigation" aria-labelledby="sepm-intro">
          <MarkdownBlock
            str={lexicon.get('paragraphs')}
            ariaHidden={isAnyModalVisible}
            id="sepm-intro"
          />
          <div className="icon-container">
            {methods.map((name: string, i: number): React.Element<'button'> => (
              <button
                ref={this.buttonRefs[i]}
                className={`icon-button ${name} ${locale}`}
                key={name}
                onClick={() => {
                  this.iconClickHandler(i);
                }}
                type="button"
                disabled={isAnyModalVisible}
                aria-hidden={isAnyModalVisible}
                tabIndex="0"
              >
                <p>{iconNames[i]}</p>
              </button>
            ))}
          </div>
        </div>
        <ModalWithOverlay
          clickHandler={this.resetActiveNumHandler}
          isModalOn={isModalOn}
          closeBox={sharedLexicon.get('closeBox')}
        >
          {isModalOn && (
            <div
              className="modal-content"
              role="group"
              aria-labelledby={`sepm-modal-${methodName}`}
            >
              <div className="modal-heading-container">
                <h1
                  ref={this.modalHeaderRef}
                  className="focus-start"
                  tabIndex="-1"
                  id={`sepm-modal-${methodName}`}
                >
                  {methodName}
                </h1>
              </div>
              <StuffContainer
                heading={lexicon.get('ranges.0')}
                list={lexicon.get(`goodStuff.${activeNum}`)}
                stuffType="good"
              />
              <StuffContainer
                heading={lexicon.get('ranges.1')}
                list={lexicon.get(`annoyingStuff.${activeNum}`)}
                stuffType="annoying"
              />
              {lexicon.get(`noWorryStuff.${activeNum}`).length > 0 ? (
                <StuffContainer
                  heading={lexicon.get('ranges.2')}
                  list={lexicon.get(`noWorryStuff.${activeNum}`)}
                  stuffType="no-worry"
                />
              ) : null}
            </div>
          )}
        </ModalWithOverlay>
      </>
    );
  }
}

const mapStateToProps = ({
  isAnyModalVisible,
}: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = {
  onModalShow: (): Object => ({
    type: 'SET_IS_ANY_MODAL_VISIBLE',
    visibility: true,
  }),
  onModalHide: (): Object => ({
    type: 'SET_IS_ANY_MODAL_VISIBLE',
    visibility: false,
  }),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideEffectsPerMethod);
